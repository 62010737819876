export default {
  // common
  'common.privacy_policy_hint': 'Mit der Nutzung dieser Website, stimmen Sie unserer Datenschutzerklärung zu.',
  'common.privacy_policy_agreed': 'I have read and accepted the privacy policy!',
  'common.privacyPolicy': 'Datenschutzerklärung',
  'common.giveitatry': 'Probier es aus!',
  'common.close': 'Schließen',
  'common.deleted': 'Gelöscht',
  'common.token_invalid': 'Der Token ist ungültig.',
  'common.save_changes': 'Änderungen speichern',
  'common.roles_loaded': 'Rollen geladen',
  'common.groups_loaded': 'Gruppen geladen',
  'common.users_loaded': 'Benutzer geladen',
  'common.items_loaded': 'Items geladen',
  'common.ores_loaded': 'Erze geladen',
  'common.recipes_loaded': 'Rezepte geladen',
  'common.scan_result_resources_loaded': 'Ressourcen für Scanergebnisse geladen',
  'common.scan_result_loaded': 'Scanergebnisse geladen',
  'common.ore_appearances_loaded': 'Erz Vorkommen geladen',
  'common.sections_loaded': 'Sektionen geladen',
  'common.organizations_loaded': 'Organisationen geladen',
  'common.stellar_objects_loaded': 'Himmelskörper geladen',
  'common.skill_loaded': 'Skills geladen',
  'common.skill_groups_loaded': 'Skill Gruppen geladen',
  'common.skill_group_loaded': 'Skill Gruppe geladen',
  'common.constructs_loaded': 'Konstrukte geladen',
  'common.players_loaded': 'Spieler geladen',
  'common.change_logs_loaded': 'Änderungsprotokolle geladen',
  'common.quotes_loaded': 'Zitate geladen',
  'common.price_logs_loaded': 'Preis Logs geladen',
  'common.projects_loaded': 'Projekte geladen',
  'common.project_comments_loaded': 'Projektkommentare geladen',
  'common.no_user_selected': 'Kein Benutzer ausgewählt.',
  'common.no_group_selected': 'Keine Gruppe ausgewählt.',
  'common.no_section_selected': 'Keine Sektion ausgewählt.',
  'common.no_stellar_object_selected': 'Kein Himmelskörper ausgewählt.',
  'common.no_item_selected': 'Kein Item ausgewählt.',
  'common.no_ore_selected': 'Kein Erz ausgewählt.',
  'common.no_skill_group_selected': 'Keine Skill Group ausgewählt.',
  'common.no_project_type_selected': 'Keine Projektart ausgewählt.',
  'common.select_user': 'Benutzer auswählen',
  'common.select_group': 'Gruppe auswählen',
  'common.search_term': 'Suchbegriff',
  'common.min_three_chars': 'Bitte gib mindestens 3 Zeichen ein.',
  'common.create': 'Erstellen',
  'common.refresh': 'Neu laden',
  'common.edit_properties': 'Eigenschaften bearbeiten',
  'common.organizations': 'Organisationen',
  'common.skill_levels': 'Skill Level',
  'common.menu': 'Menü',
  'common.logout': 'Ausloggen',
  'common.file_browser': 'Datei-Browser',
  'common.skill_tree': 'Fähigkeitenbaum',
  'common.skill_tree_split': 'Fähigkeiten-baum',
  'common.crafting_calculator': 'Crafting Calculator',
  'common.members': 'Mitglieder',
  'common.org_chart': 'Org Diagramm',
  'common.total': 'Gesamt',
  'common.add': 'Hinzufügen',
  'common.delete': 'Löschen',
  'common.confirmed': 'Bestätigt',
  'common.are_you_sure': 'Bist du sicher?',
  'common.do_you_want_to_delete': 'Möchtest Du diesen Datensatz wirklich dauerhaft vom Server löschen?',
  'common.yes_delete_it': 'Ja, weg damit!',
  'common.show_all': '-- Alles zeigen --',
  'common.data_loaded': 'Daten wurden neu geladen',
  'common.not_email_username': 'Nicht deine E-Mail, dein Benutzername.',
  'common.hint_confirmation_token': 'Der Token, den du in der E-Mail bekommen hast. Muss 200 Zeichen lang sein.',
  'common.select_one_or_more': 'Eins oder mehrere auswählen',
  'common.remove_this_item': 'Dieses Item entfernen',
  'common.add_an_item': 'Ein Item hinzufügen',
  'common.unsaved_changes': 'Ungespeicherte Änderungen',
  'common.no_reference_selected': 'Keine Referenz ausgewählt',
  'common.unsaved_changes_warning': 'Es gibt ungespeicherte Änderungen. Beim Fortfahren gehen sie verloren.',
  'common.just_du_it': 'Tue es einfach!',
  'common.back': 'Zurück',
  'common.cancel': 'Abbrechen',
  'common.comment': 'Kommentieren',
  'common.statistics': 'Statistiken',
  'common.edit': 'Bearbeiten',
  'common.add_user_to_role_question': 'Willst du wirklich den Benutzer <span class="text--primary">$user</span> zu der Rolle <span class="text--primary">$role</span> hinzufügen?',
  'common.remove_user_from_role_question': 'Willst du wirklich den Benutzer <span class="text--primary">$user</span> aus der Rolle <span class="text--primary">$role</span> entfernen?',
  'common.saved': 'Gespeichert',
  'common.show_hidden': 'Versteckte anzeigen',
  'organizationMemberships': 'Organisations-Mitgliedschaften',
  'joinedAt': 'Beitritt',
  'leftAt': 'Austritt',
  'addMembership': 'Mitgliedschaft hinzufügen',
  'organizationLogs': 'Organisations-Logs',
  'playerLogs': 'Spieler-Logs',
  'showPlayerLogs': 'Zeige Spieler-Logs',
  'organizationMembershipLogs': 'Organisations-Mitgliedschafen Logs',
  'filterByPlayer': 'Spieler-Filter',
  'filterByChildOrganization': 'Unterorganisation-Filter',
  'filterByOrganization': 'Organisation-Filter',
  'filterByOre': 'Ore-Filter',
  'filterByUser': 'Benutzer-Filter',
  'filterByStellarObject': 'Himmelskörper-Filter',
  'player': 'Spieler',
  'organization': 'Organisation',
  'childOrganization': 'Unterorganisation',
  'membershipType': 'Mitgliedschaftsart',
  'copyUri': 'Kopiere die Uri in den Zwischenspeicher',
  'uriCopied': 'Uri im Zwischenspeicher',
  'fullIngredientCalculationExplanation': 'Berücksichtige alle Folgerezepte und die Herstellungszeit dieses Items (standard).',
  'ignoreFollowUpExplanation': 'Ignoriere alle Folgerezepte aber berücksichtige die Herstellungszeit dieses Items. Benutze diese Einstellung, wenn du alles auf Vorat hast, das du zur Herstellung dieses Items benötigst, aber das Item selbst noch herstellen musst.',
  'ignoreFollowUpAndCraftingTimeExplanation': 'Ignoriere alle Folgerezepte und die Herstellungszeit dieses Items. Benutze diese Einstellung, wenn du dieses Item schon in der benötigten Menge auf Vorrat hast.',
  'ignoreIngredientConfigurations': 'Ignoriere Bestandteil-Konfigurationen',
  'reportOreAppearance': 'Erzerscheinung melden',
  'reportOreAppearanceExplanation': '<p>Hier kannst du melden welches Erz du auf welchem Mond oder Planeten gefunden hast. Bitte beachte, dass die <strong class="text--cyan">Tiefe von der Oberfläche</strong> und nicht von der Höhenangabe rechts oben im Spiel gemessen wird.</p><p><strong>Beispiel:</strong><br/>Die <span class="text--cyan">Oberflächenhöhe beträgt 20 Meter</span> und du findest das Erz auf einer Höhe von <span class="text--cyan">-200 Meter</span>, dann beträgt die zu meldende Höhenangabe <span class="text--cyan">-220 Meter</span>.</p><p>Du kannst einen Bereich von der höchsten bis zur tiefsten Entdeckung angeben oder einfach beide Werte auf einen setzen.</p><p>Unsere Verantwortlichen werden deinen Report zeitnah prüfen und annehmen oder ablehen. Wenn dein Report abgelehnt wird, sei bitte nicht beleidig, es gib sicher einen Grund dafür.</p><p>Vielen Dank für deine Beteiligung!</p>',
  'thankYou': 'Vielen lieben Dank!',
  'sayThankYouForOreAppearanceReport': '<p>Vielen Dank für deinen Report <span class="text--cyan">$user</span>. Wir werden ihn so schnell wie möglich überprüfen.</p>',
  'oreAppearanceReports': 'Erz Erscheinungs Berichte',
  'oreAppearanceReportsSplit': 'Erz Erscheinungs Berichte',
  'editOreAppearanceReport': 'Erz Erscheinungs-Bericht bearbeiten',
  'merge': 'annehmen',
  'merged': 'angenommen',
  'decline': 'ablehnen',
  'declined': 'abgelehnt',
  'highestAlt': 'Höchste Höhe',
  'lowestAlt': 'Niedrigste Höhe',
  'oreAppearanceReportDoYouWantToMerge': 'Willst du wirklich diesen Erzerscheinungs-Bericht annehmen?',
  'oreAppearanceReportDoYouWantToDecline': 'Willst du wirklich diesen Erzerscheinungs-Bericht ablehnen?',
  'size': 'Größe',
  'onlyDirect': 'Nur direkte',
  'itemReports': 'Item reports',
  'filterByItem': 'Item-Filter',
  'reportItemExplanation': '<p>Hier kannst du Fehler oder Änderungen bei Items melden. Das können Eigenschaften der Items selbst oder Crafting Rezepte sein.</p><p>Bitte beachte hierbei, dass die Eigenschaften von Items und deren Crafting Rezepte ohne die Beachtung von Skill gespeichert werden. Die Skills werden später in die Berechnung einfließen.</p>',
  'itemReportDoYouWantToMerge': 'Willst du diesen Item Report wirklich bestätigen?',
  'itemReportDoYouWantToDecline': 'Willst du diesen Item Report wirklich ablehnen?',
  'editItemReport': 'Item report bearbeiten',
  'editItem': 'Item bearbeiten',
  'reportItem': 'Report item',
  'filterByGroup': 'Gruppen-Filter',
  'showAll': 'Alle anzeigen',
  'editNews': 'News bearbeiten',
  'meters': 'Meter',
  'seconds': 'Sekunden',
  'skip': 'Überspringen',
  'previous': 'Zurück',
  'next': 'Weiter',
  'finish': 'Beenden',
  'mass': 'Gewicht',
  'craftingTime': 'Herstellungszeit',
  'linearCraftingTime': 'Lineare Herstellungszeit',
  'oreBuyValue': 'Erz-Einkaufswert',
  'oreSellValue': 'Erz-Verkaufswert',
  'endProducts': 'Endprodukte',
  'totals': 'Gesamtwerte',
  'questionDeleteEndProduct': 'Möchtest du wirklich dieses Endprodukt von der Herstellungsliste entfernen?',
  'craftingList': 'Herstellungsliste',
  'editCraftingList': 'Herstellungsliste bearbeiten',
  'save': 'Speichern',
  'addAnEndProduct': 'Endprodukt hinzufügen',
  'createCraftingListFirst': 'Speicher zuerst die Herstellungsliste und füge Endprodukte hinzu.',
  'craftingLists': 'Herstellungslisten',
  'createACraftingList': 'Herstellungsliste anlegen',
  'sorting': 'Sortierung',
  'pleaseSelectItem': 'Bitte wähle ein Item',
  'amountTooSmall': 'Menge muss größer sein als 0',
  'browserHint': 'Firefox bereitet uns gerade einige Probleme, deshalb Empfehlen wir euch für die best mögliche Darstellung und Funktionalität einen Chromium basierten Browser wie z. B. Chrome, Opera, Vivaldi oder den neusten Edge. Wir empfehlen keinen Browser direkt. Die Wahl liegt bei euch.',
  'cookieDisclaimer': 'Unsere Website verwendet Cookies um mehrere Funktionen wie, Seitenbesuche, Single Sign On und persönliche Konfigurationen zu ermöglichen. Damit unsere Website einwandfrei funktionieren kann, muss die Nutzung von Cookies erlauben. Du kannst jederzeit in den Entwicklerwerkzeugen deines Browsers die Cookies überprüfen.',
  'cookieQuestion': 'Ich gestatte die Nutzung von Cookies.',
  'isFaction': 'Ist Fraktion',
  'clickToSeeInfoAndPlayers': 'Klicken um zu sehen wer den Skill-Level freigeschaltet hat',
  'members': 'Mitglieder',
  'library': 'Bibliothek',
  'valuesForOneQubicMeter': 'Werte für 1 m³',
  'itemVariationsLibrary': 'Element-Variations-Bibliothek',
  'newton': 'Newton',
  'literPerKilonewton': 'Liter pro Kilonewton',
  'literPerHour': 'Liter pro Stunde',
  'secondsToT50Thrust': 'Sekunden bis 50% Schub',
  'parentSkillGroup': 'Ober-Skill-Gruppe',
  'noParentSkillGroupSelected': 'Keine Über-Skill-Gruppe gewählt',
  'credentialsHint': 'Du musst NICHT deinen Dual Universe Login nutzen. Du kanntrs E-Mail, Benutzername und Passwort frei wählen!',
  'public': 'öffentlich',
  'projectImages': 'Projektbilder',
  'selectProjectImages': 'Projektbilder auswählen',
  'addProjectImages': 'Projektbilder und -videos hinzufügen',
  'editProjectImagesHint1': 'Du kannst Projektbilder und -videos neu anordnen, indem du sie an die gewünschte Position ziehst. Da ist nur dann möglich, wenn es keine ungespeicherten neue oder gelöschte Projektbilder und -videos gibt.',
  'editProjectImagesHint2': 'Du kannst mehrere Projektbilder und -videos mit klick auf das Plus Symbol hinzufügen. Das ist nur dann möglich, wenn es keine ungespeicherten Sortierungen gibt.',
  'editProjectImagesHint3': 'Du kannst mehrere Projektbilder und -videos vom Projekt löschen, indem du auf den Papierkorb klicks. Die eigentliche Datei wird dabei nicht gelöscht. Das ist nur dann möglich, wenn es keine ungespeicherten Sortierungen gibt.',
  'filterByProjectType': 'Projekttyp-Filter',
  'filterByProjectState': 'Projektstatus-Filter',
  'playerRegistration': 'Spieler Registration',
  'playerRegistrationExplanation': '<p>Um Spieler-bezogene Funktionen verwenden zu können, musst du deine(n) Spieler mit deinem Account verknüpfen.</p><p>Um das zu tun, gehe zu einer <span class="text--teal">Surrogate Station</span> und wähle das Ziel <span class="text--teal">Hyperium Player Registration</span> aus. Surrogate Stations findest du an jedem Markt. Der Screenshot über diesem Text zeigt welche.</p><p>Folge den Anweisungen, die du dort findest. Du wirst den Token unterhalb dieses Textes eingeben müssen.</p>',
  'addPlayer': 'Spieler hinzufügen',
  'playerRegistrationLogs': 'Spieler Registrierungs Logs',
  'successful': 'Erfolgreich',
  'message': 'Nachricht',
  'manageOrganizations': 'Organisationen verwalten',
  'enableOrganizationView': 'Organisations-Ansicht aktivieren',
  'organizationViewExplanation': '<img style="height: auto; width: 100%;" src="https://cdn.hyperion-corporation.de/ui/organization-view.jpg"/><p>Die <span class="text--teal">Organisations-Ansicht</span> gibt dir eine Übersicht über alle Skill aller Mitglieder eurer Organisation.</p><p>Sie muss für jede Organisation einzeln aktiviert werden, was nur von einem <span class="text--teal">(Super)Legate</span> der jeweiligen Organisation gemacht werden kann.</p><p>Die Liste unten zeigt alle Organisationen, bei denen du Spieler mit deinem Account verbunden hast und die mindestens Legate in der der Organisation sind.</p><p>Wenn du diese Funktion für deine Organisation aktivierst, können <span class="text--teal">alle Mitglieder dieser Organisation</span> alle Skills aller anderen Mitglieder der Organisation sehen, wenn sie ihren Spieler mit ihrem account verknüft haben.</p><p>Du kannst Spieler mit deinem Account verknüpfen indem du auf den <span class="text--teal">Spieler hinzufügen</span> Button im Skill-Tree klickst.</p><p>Wenn du keine Organisation in der Liste unten siehst, ist kein Spieler von dir mindestens Legate in einer Organisation. In diesem Fall frag einen Legaten aus deiner Organisation, ob er die Funktion für euch einschaltet.</p>',
  'vQuantaPrice': 'vQuanta Preis',
  'vQuantaPriceTotal': 'Gesamt vQuanta Preis',
  'A': 'M',
  'TCT': 'THZ',
  'RCT': 'RHZ',
  'TLCT': 'TLHZ',
  'RLCT': 'RLHZ',
  'BS': 'SG/SA',
  'OPH': 'PPS',
  'MOPH': 'MPPS',
  'CPH': 'KPS',
  'RI': 'BI',
  'RIA': 'EIA',
  'showName': 'Zeige Name',
  'showIcon': 'Zeige Icon',
  'showAmount': 'Zeige Menge',
  'showTCT': 'Zeige Totale Herstellungszeit',
  'showRCT': 'Zeige Rekursive Herstellungszeit',
  'showTLCT': 'Zeige Totale Lineare Herstellungszeit',
  'showRLCT': 'Zeige Rekursive Lineare Herstellungszeit',
  'showBS': 'Zeige Stapelgröße / -anzahl',
  'showOPH': 'Zeige Produktion pro Stunde',
  'showMOPH': 'Zeige Maximale Produktion pro Stunde',
  'showCPH': 'Zeige Konsum pro Stunde',
  'showRI': 'Zeige Benötigte Industrien',
  'showRIA': 'Zeige Empfohlene Industrie Anzahl',
  'showOptions': 'Zeige Optionen',
  'hideOptions': 'Verstecke Optionen',
  'amount': 'Menge',
  'endIndustriesAmount': 'End-Industrien Menge',
  'tctLegend': 'Totale Herstellungszeit (Totale Lineare Herstellungszeit / Empfohlene Industry Anzahl)',
  'rctLegend': 'Rekursive Herstellungszeit (THZ mit Kindern)',
  'tlctLegend': 'Totale Lineare Herstellungszeit (Herstellungszeit * Menge)',
  'rlctLegend': 'Rekursive Lineare Herstellungszeit (TLHZ mit Kindern)',
  'bsLegend': 'Stapelgröße / Stapelanzahl',
  'ophLegend': 'Produktion pro Stunde, wieviel von diesem Item kann von einer Industrie in einer Stunde produziert werden',
  'mophLegend': 'Maximale Produktion pro Stunde, wieviel von diesem Item kann von der empfohlenen Industry Anzahl in einer Stunde produziert werden',
  'cphLegend': 'Konsum pro Stunde, wie viel von diesem Item wird von dem Eltern-Knotenpunkt pro Stunde benötigt. Die Berechnung beginnt mit der maximalen Produktion pro Stunde des ersten Knotenpunktes.',
  'riLegend': 'Benötigte Industrien',
  'riaLegend': 'Empfohlene Industrie Anzahl',
  'showCraftingTree': 'Zeige Herstellungsbaum',
  'expandTree': 'Baum ausfahren',
  'collapseTree': 'Baum einfahren',
  'hintHorizontalScroll': 'Benutze <span class="text--teal text--code">Umschalt</span> + <span class="text--teal text--code">Mausrad</span> zum horizontalen Scrollen.',

  'shipImages': 'Schiffsbilder',
  'selectShipImages': 'Schiffstbilder auswählen',
  'addShipImages': 'Schiffsbilder und -videos hinzufügen',
  'editShipImagesHint1': 'Du kannst Schiffsbilder und -videos neu anordnen, indem du sie an die gewünschte Position ziehst. Da ist nur dann möglich, wenn es keine ungespeicherten neue oder gelöschte Schiffsbilder und -videos gibt.',
  'editShipImagesHint2': 'Du kannst mehrere Schiffsbilder und -videos mit klick auf das Plus Symbol hinzufügen. Das ist nur dann möglich, wenn es keine ungespeicherten Sortierungen gibt.',
  'editShipImagesHint3': 'Du kannst mehrere Schiffsbilder und -videos vom Schiff löschen, indem du auf den Papierkorb klicks. Die eigentliche Datei wird dabei nicht gelöscht. Das ist nur dann möglich, wenn es keine ungespeicherten Sortierungen gibt.',
  'shipClass': 'Schiffsklasse',
  'noShipClassSelected': 'Keine Schiffsklasse ausgewählt',
  'coreSize': 'Kerngröße',
  'noOrganizationSelected': 'Keine Organisation ausgewählt',
  'ships': 'Schiffe',
  'editShipClass': 'Schiffsklasse bearbeiten',
  'color': 'Farbe',
  'shipClasses': 'Schiffsklassen',
  'editShip': 'Schiff bearbeiten',
  'filterByShipClass': 'Schiffsklassen-Filter',
  'filterByContributor': 'Mitwirkender-Filter',
  'filterByCoreSize': 'Kerngrößen-Filter',
  'thxForLove': 'Danke für deine Liebe!',
  'brokenHeart': 'Ein gebrochenes Herz mehr...',
  'hidden': 'versteckt',
  'scale': 'Größe',
  'requiredIndustries': 'Benötigte Industrien',
  'tier': 'Tier',
  'hitpoints': 'Trefferpunkte',
  'resistancesAntimatter': 'Resistenz Antimatierie',
  'resistancesElectromagnetic': 'Resistenz Elektromagnetisch',
  'resistancesKinetic': 'Resistenz Kinetisch',
  'resistancesThermic': 'Resistenz Thermisch',
  'resistancesAntimatterHp': 'Normalisierte Antimatierie TP',
  'resistancesElectromagneticHp': 'Normalisierte Elektromagnetisch TP',
  'resistancesKineticHp': 'Normalisierte Kinetisch TP',
  'resistancesThermicHp': 'Normalisierte Thermisch TP',
  'resistancesAntimatterHpPerMass': 'Normalisierte Antimatierie TP / Masse',
  'resistancesElectromagneticHpPerMass': 'Normalisierte Elektromagnetisch TP / Masse',
  'resistancesKineticHpPerMass': 'Normalisierte Kinetisch TP / Masse',
  'resistancesThermicHpPerMass': 'Normalisierte Thermisch TP / Masse',
  'clickForSortByRow': 'Klicken um nach dieser Zeite zu sortieren.',
  'bot_price': 'Bot price',
  'open': 'offen',
  'in-progress': 'laufend',
  'done': 'erledigt',
  'cancelled': 'abgebrochen',
  'state': 'Status',
  'role': 'Rolle',
  'no_role_selected': 'Keine Rolle ausgewählt',
  'assignedTo': 'Zugewiesen an',
  'restrictViewAccessTo': 'Lesezugriff einschränken auf',
  'editTodo': 'Todo bearbeiten',
  'creator': 'Ersteller',
  'notAssigned': 'nicht zugewiesen',
  'edit': 'Bearbeiten',
  'assignToMe': 'Mir zuweisen',
  'letMeDuIt': 'Let me DU it',
  'assignToMeQuestionTitle': 'Willst du diese Aufgabe wirklich dir zuweisen?',
  'assignToMeDefaultQuestionText': '<p>Wenn du diese Verantwortung übernimmst, verlassen sich Leute auf dich.</p><p>Nimm die Aufgabe bitte ernst und erfülle sie mit <span class="text--love">Leidenschaft</span>.</p>',
  'assignToMeConflictQuestionText': '<p class="text--uppercase text--error">Wichtig</p><p>Diese aufgabe ist bereits <span class="text--teal">$username</span> zugewiesen.</p><p>Bitte übernehme diese Aufgabe nur, wenn du mit allen verantwortlichen Personen gesprochen hast.</p>',
  'myTodos': 'Meine Todos',
  'priority': 'Priorität',

  'tourTitleOre': 'Wähle ein Erz',
  'tourContentOre': `<div class="bodytext"><p><span class="text--teal">Klick auf ein Erz</span> um nur stellare Objekte anzuzeigen, auf denen das Erz entdeckt wurde.</p><p>Du kannst auf <span class="text--teal">mehrere</span> klicken und es werden alle angezeigt, auf denen mindestens ein Erz gefunden wurde.</p><p>Mit erneuem Klicken auf ein Erz, entfernst du den Filter.</p></div>`,
  'tourTitleStellarObject': 'Wähle ein stellares Objekt',
  'tourContentStellarObject': `<div class="bodytext"><p><span class="text--teal">KLicke auf ein </span> um dessen Zeile hervorzuheben.</p><p>Du kannst <span class="text--teal">mehrere</span> anklicken.</p><p>Durch erneues Anklicken, kannst du die Hervorhebung wieder entfernen.</p></div>`,
  'tourTitleMoreInfo': 'More informations?',
  'tourContentStellarObjectDetails': `<div class="bodytext"><p><span class="text--teal">Klick auf das Info Symbol</span> an einem stellaren Objekt, um mehr Informationen darüber zu bekommen. Wie zum Beispiel: Gibt es eine Atmosphäre, wie stark ist die Gravitation, Luftdichte, etc.</p><p>Das kann bei der nächsten Mining-Tour nützlich sein, denn du möchtest nicht auf einem anderen Planeten stecken bleiben.</p></div>`,
  'tourTitleBugDiscovery': 'Ein Fehler? Neue Entdeckung?',
  'tourContentReportOreAppearance': `<div class="bodytext"><p>Die Erzerscheinungen sind möglicherweise unvollständig oder falsch. Wenn du also etwas entdeckst, dass die Tabelle nicht abdeckt, kann du einen <span class="text--teal">Erz-Erscheinungs-Report</span> machen. Klick einfach auf den Button und füll das Formular aus. Wir werden uns einen Bericht schnellstmöglich ansehen und die Daten übernehmen.</p></div>`,

  'tourCCTitleBasicInfos': 'Basis Infos',
  'tourCCContentBasicInfos': `<div class="bodytext"><p>Hier kannst du Basis-Informationen eines Items sehen zusammen mit der <span class="text--teal">Kategoriebeschreibung</span> und der <span class="text--teal">Item Beschreibung</span> wenn verfügbar.</p></div>`,
  'tourCCTitleTotalCraftingTime': 'Totale Herstellungszeit',
  'tourCCContentTotalCraftingTime': `<div class="bodytext"><p>Das ist die <span class="text--teal">benötigte Zeit</span> um dieses Item und alle dafür benötigten Items nacheinander <span class="text--teal">ohne Parallelisierung</span> herzustellen.</p></div>`,
  'tourCCTitleTotalOreSellValue': 'Gesamter Erz Verkauswert',
  'tourCCContentTotalOreSellValue': `<div class="bodytext"><p>Du kannst jedes <span class="text--teal">Erz</span> an den <span class="text--teal">Bot</span> am Markt verkaufen. Das hier ist der <span class="text--teal">Wert in Quanta</span> (die ingame Währung) den der Bot dir zahlen würde, <span class="text--teal">wenn du ihm die Erze verkaufst</span>, die benötigt werden um dieses Item herzustellen.</p></div>`,
  'tourCCTitleRequiredIndustries': 'Benötigte Industrien',
  'tourCCContentRequiredIndustries': `<div class="bodytext"><p>Kleinere Items können im <span class="text--teal">Nanoformer</span> hergestellt werden, aber es wird die <span class="text--teal">doppelte Zeit</span> dauern. Der beste Weg ist es eine <span class="text--teal">Industrie</span> zu verwenden. Für größere und welche mit höherem Level wirst du ohnehin eine Industry brauchen. Hier siehst du die Industrie, die dieses Item herstellen kann. Manche Items können in mehreren Industrien hergestellt werden, <span class="text--teal">aber du benötigst nur eine</span>. Fahre mit der Maus über eine Industrie um ihren Namen zu sehen.</p></div>`,
  'tourCCTitleAmount': 'Menge',
  'tourCCContentAmount': `<div class="bodytext"><p>Hier kannst du einstellen <span class="text--teal">wie viel</span> du von diesem herstellen willst. Die <span class="text--teal">Gesamtwerte</span> und <span class="text--teal">Bestandteile</span> werden <span class="text--teal">für diese Menge</span> berechnet.</p></div>`,
  'tourCCTitleCalculate': 'Berechnen',
  'tourCCContentCalculate': `<div class="bodytext"><p>Wenn du die Menge verändert hast, denk daran das Item neu zu <span class="text--teal">berechnen</span>.</p></div>`,
  'tourCCTitleIgnoreIngredientConfigs': 'Ignoriere Bestandteil-Konfigurationen',
  'tourCCContentIgnoreIngredientConfigs': `<div class="bodytext"><p>Du kannst <span class="text--teal">Bestandteil-Konfigurationen in den Berechnungen ignorieren</span>. Wir werden diese später in der Tour erklären. Wenn du eine <span class="text--teal">komplette Berechnung</span> willst, die alle Konfigurationen ignoriert, <span class="text--teal">aktiviere diese Option</span>. Wenn deine Berechnungen berücksichtigt werden sollen, <span class="text--teal">schalte sie aus</span>.</p></div>`,
  'tourCCTitleOnlyDirect': 'Nur direk',
  'tourCCContentOnlyDirect': `<div class="bodytext"><p>Das Herstellungsergebnis wird <span class="text--teal">rekursiv</span> berechnet. Das bedeutet es berechnet alle Bestandteile und deren Bestandteile. Wenn du diese Option einschaltest, <span class="text--teal">werden nur direkte Bestandteile berechnet</span>. Das kann z. B. nützlich sein, wenn du den Eingangs-Container einer Fabrik befüllen willst.</p></div>`,
  'tourCCTitlePriceLogs': 'Preis-Logs',
  'tourCContentPriceLogs': `<div class="bodytext"><p>Wir werden in der Zukunft Preise an den Märkten sammeln und <span class="text--teal">eine Historie für diese</span> anbieten. Das kann nützlich sein, wenn du ein Item kaufen willst.</p></div>`,
  'tourCCTitleDataSheet': 'Datentabelle',
  'tourCCContentDataSheet': `<div class="bodytext"><p>Diese Tabelle zeigt alle Informationen über dieses Item. Die Werte gelten für <span class="text--teal">ein Stück oder einen Liter</span> des aktuellen Items. <span class="text--teal">Talente werden nicht berücksichtigt</span>. Es werden nur rohe Daten angezeigt.</p></div>`,
  'tourCCTitleIngredients': 'Bestandteile',
  'tourCCContentIngredients': `<div class="bodytext"><p>Dies ist die Liste der Bauteile, Materialen, etc. die benötigt werden um dieses Item und seine Bestandteile herzustellen, also es ist <span class="text--teal">rekursiv</span> generiert.</p></div>`,
  'tourCCTitleIngredientGroups': 'Bestandteile-Gruppen',
  'tourCCContentIngredientGroups': `<div class="bodytext"><p>Du kannst die <span class="text--teal">Bestandteile</span> nach ihren <span class="text--teal">Gruppen filtern</span>. Die Liste der Gruppen variiert je nach den Bestandteilen. Mit Klicken auf das linke <span class="text--teal">Asterisk</span> Symbol, kannst du wieder <span class="text--teal">alle</span> Bestandteile anzeigen.</p></div>`,
  'tourCCTitleIngredient': 'Bestandteil',
  'tourCCContentIngredient': `<div class="bodytext"><p>Jede Zeile repräsentiert <span class="text--teal">einen Bestandteil</span>.</p></div>`,
  'tourCCTitleIngredientIcon': 'Bestandteils-Symbol',
  'tourCCContentIngredientIcon': `<div class="bodytext"><p>Das ist das Bestandteils-<span class="text--teal">Symbol</span></p></div>`,
  'tourCCTitleIngredientName': 'Bestandteils-Name',
  'tourCCContentIngredientName': `<div class="bodytext"><p>Der <span class="text--teal">Name</span> des Bestandteils</p></div>`,
  'tourCCTitleIngredientTier': 'Bestandteils-Tier',
  'tourCCContentIngredientTier': `<div class="bodytext"><p>und das <span class="text--teal">Tier</span> (Stufe) des Bestandteils. Höhere Stufen benötigen Resourcen mit höheren Stufen und haben längere Herstellungszeiten.</p></div>`,
  'tourCCTitleIngredientAmount': 'Bestandteils-Menge',
  'tourCCContentIngredientAmount': `<div class="bodytext"><p>Das ist die benötigte <span class="text--teal">Menge</span> des Bestandteils in <span class="text--teal">Stück oder Litern</span>.</p></div>`,
  'tourCCTitleIngredientCraftingTime': 'Herstellungszeit',
  'tourCCContentIngredientCraftingTime': `<div class="bodytext"><p>Das ist die <span class="text--teal">Zeit</span> die benötigt wird um den Bestandteil in der benötigten Menge <span class="text--teal">mit einer Industrie</span> herzustellen. Die Herstellung mit dem <span class="text--teal">Nanoformer</span> wird <span class="text--teal">doppelt so lange</span> dauern.</p></div>`,
  'tourCCTitleIngredientConfigurations': 'Bestandteils-Konfigurationen',
  'tourCCContentIngredientConfigurations': `<div class="bodytext"><p>Du kannst konfigurieren <span class="text--teal">wie Bestandteile berechnet</span> werden, je nach Szenario. Wir werden nun jedes Szenario erklären. Bedenke, dass du die Option <span class="text--teal">Ignoriere Bestandteils-Konfigurationen</span> ausgeschaltet hast oder sie werden ignoriert.</p></div>`,
  'tourCCTitleIngredientConfigurationFull': 'Komplett (standard)',
  'tourCCContentIngredientConfigurationFull': `<div class="bodytext"><p>Dies ist der <span class="text--teal">Standard-Modus</span>. Er berechnet alle Unterbestandteile und die eigene Herstellungszeit.</p></div>`,
  'tourCCTitleIngredientConfigurationIgnoreSub': 'Ignoriere Unterbestandteile',
  'tourCCContentIngredientConfigurationIgnoreSub': `<div class="bodytext"><p>In diesem Modus wird die Berechnung <span class="text--teal">Unterbestandteile für diesen Bestandteil ignorieren</span> aber <span class="text--teal">die Herstellungszeit für die benötigte Menge berücksichtigen</span>. Benutze diese Konfiguration, wenn du alle Unterbestandteile bereits besitzt, die für die Herstellung dieses Bestandteils benötigt werden, aber du den Bestandteil selbst noch in der benötigten Menge herstellen musst.</p></div>`,
  'tourCCTitleIngredientConfigurationIgnore': 'Komplett ignorieren',
  'tourCCContentIngredientConfigurationIgnore': `<div class="bodytext"><p>In diesem Modus wird die Kalkulation <span class="text--teal">den Bestandteil</span>, <span class="text--teal"> die Unterbestandteile </span> und die <span class="text--teal">Herstellungszeit</span> für den Bestandteil <span class="text--teal">ignorieren</span>. Benutze diese Konfiguration, wenn du den Bestandteil bereits in der benötigten Menge besitzt oder für Catalysts, die du beim Herstellen wieder zurück bekommst.</p></div>`,
  'tourCCTitleIngredientIndustries': 'Benötigte Industrien',
  'tourCCContentIngredientIndustries': `<div class="bodytext"><p>Hier siehst du welche <span class="text--teal">Industrien benötig werden</span> um diesen Bestandteil herzustellen. Manche Bestandteile sind in <span class="text--teal">mehreren</span> Industrien herstellbar, aber <span class="text--teal">du benötigst nur eine</span>. Manche Bestandteile sind auch im <span class="text--teal">Nanoformer</span> herstellbar, aber es wird die <span class="text--teal">doppelte Zeit</span> benötigen. Fahre mit der Maus über eine Industry um ihren Namen zusehen.</p></div>`,
  'tourCCTitleUsages': 'Verwendungen',
  'tourCCContentUsages': `<div class="bodytext"><p>Diese Liste zeigt welche Items dieses Item in der Herstellung verwenden, <span class="text--teal">also wo es ein Bestandteil ist</span>. Die Liste wir <span class="text--teal">rekursiv</span> aufgelöst, aber wenn du die Option <span class="text--teal">Nur direkt</span> einschaltest, werden nur Items angezeit wo dieses Item ein direkter Bestandteil ist.</p></div>`,
  'tourCCTitleReport': 'Melde uns Änderungen',
  'tourCCContentReport': `<div class="bodytext">Leider kann es passieren, dass <span class="text--teal">Itemdaten oder Herstellungsrezepte verändert werden</span>, speziell in der aktuellen Phase der Entwicklung. Wenn du einen Fehler findest, <span class="text--teal">zögere nicht uns zu berichten, was du gefunden hast</span>. Wir werden uns deinen Bericht so schnell wie möglich ansehen. <span class="text--teal">Wir sind jedem sehr dankbar</span>, der uns hilft die Qualität des Projektes hoch zu halten!</div>`,


  'system': 'System',
  'AntiGravMinAltitude': 'Minimale Antigrav Höhe',
  'atmosphericDensityAboveSurface': 'Atmo Dichte über der Oberfläche',
  'atmosphericEngineMaxAltitude': 'Max Höhe für Atmo-Triebwerke',
  'biosphere': 'Biosphäre',
  'bodyId': 'InGame Id',
  'classification': 'Klassifikation',
  'discoveredBy': 'Entdeckt von',
  'fullAtmosphericDensityMaxAltitude': 'Max Höhe bei voller Atmo-Dichte',
  'GM': 'Gravitational Constant X Mass',
  'gravity': 'Gravitation',
  'habitability': 'Bewohnbarkeit',
  'hasAtmosphere': 'Hat Atmosphäre',
  'isSanctuary': 'Ist Heiligtum',
  'noAtmosphericDensityAltitude': 'Keine Atmo-Dichte ab Höhe',
  'numSatellites': 'Anzahl Satelliten',
  'positionFromSun': 'Position von der Sonne',
  'positionX': 'Position X',
  'positionY': 'Position Y',
  'positionZ': 'Position Z',
  'radius': 'Radius',
  'safeAreaEdgeAltitude': 'Sicherheitszone Höhe',
  'spaceEngineMinAltitude': 'Raum-Triebwerke Minimale Höhe',
  'surfaceArea': 'Oberflächen Größe',
  'surfaceAverageAltitude': 'Durchschnittliche Oberflächen Höhe',
  'surfaceMaxAltitude': 'Maximale Oberflächen Höhe',
  'surfaceMinAltitude': 'Minimale Oberflächen Höhe',
  'systemZone': 'System Zone',
  'territories': 'Gebiete',
  'type': 'Typ',
  'waterLevel': 'Wasser-Level',




  // entities common
  'entity.common.id': 'Id',
  'entity.common.title': 'Titel',
  'entity.common.created_at': 'Erstellt am',
  'entity.common.updated_at': 'Aktualisiert am',
  'entity.common.section': 'Sektion',
  'entity.common.sections': 'Sektionen',
  'entity.common.group': 'Gruppe',
  'entity.common.groups': 'Gruppen',
  'entity.common.role': 'Role',
  'entity.common.roles': 'Roles',
  'entity.common.item': 'Item',
  'entity.common.items': 'Items',
  'entity.common.user': 'Benutzer',
  'entity.common.users': 'Benutzer',
  'entity.common.description': 'Beschreibung',
  'entity.common.subdescription': 'Unterbeschreibung',
  'entity.common.size': 'Größe',
  'entity.common.position': 'Position',
  'entity.common.icon': 'Icon',
  'entity.common.volume': 'Volumen',
  'entity.common.volume_in_liters': 'Volumen in Liter',
  'entity.common.stellar_object': 'Himmelsobjekt',
  'entity.common.stellar_objects': 'Himmelsobjekte',
  'entity.common.stellar_objects_split': 'Himmels-objekte',
  'entity.common.need_review': 'Muss überprüft werden',
  'entity.common.community_url_slug': 'Community url slug',
  'entity.common.name': 'Name',
  'entity.common.skill_group': 'Skill Gruppe',
  'entity.common.sorting': 'Sortierung',
  'entity.common.tier': 'Tier',
  'entity.common.change_logs': 'Änderungsprotokolle',
  'entity.common.change_logs_split': 'Änderungs-protokolle',
  'entity.common.scan_result': 'Scanergebnis',
  'entity.common.scan_results': 'Scanergebnisse',
  'entity.common.scan_results_split': 'Scan-ergebnisse',
  'entity.common.organization': 'Organisation',
  'entity.common.organizations': 'Organisationen',
  'entity.common.organizations_split': 'Organi-sationen',
  'entity.common.player': 'Spieler',
  'entity.common.players': 'Spieler',
  'entity.common.construct': 'Konstrukt',
  'entity.common.constructs': 'Konstrukte',
  'entity.common.project': 'Projekt',
  'entity.common.projects': 'Projekte',
  'entity.common.project_type': 'Projektart',
  'entity.common.project_types': 'Projektarten',
  'entity.common.ore': 'Erz',
  'entity.common.ore_appearance': 'Erzerscheinung',
  'entity.common.ore_appearances': 'Erzerscheinungen',
  'entity.common.ore_appearances_split': 'Erzersch-einung',
  'entity.common.quote': 'Zitat',
  'entity.common.quotes': 'Zitate',
  'entity.common.type': 'Art',
  'entity.common.tile': 'Kachel',
  'entity.common.prime': 'Prime',
  'entity.common.vice': 'Vize',
  'entity.common.storage': 'Lager',
  'entity.common.storages': 'Lagers',
  'entity.common.storageContent': 'Lagerinhalt',
  'entity.common.storageContents': 'Lagerinhalte',
  'entity.common.storageTransaction': 'Lager Transaktion',
  'entity.common.storageTransactions': 'Lager Transaktionen',
  'entity.common.hidden': 'Versteckt',
  'entity.common.constructLogs': 'Konstrukt Logs',

  // entities
  'entity.auth.group.roles': 'Rolle',
  'entity.auth.group.parent': 'Übergeordnete Gruppe',
  'entity.auth.group.gitlab_group_ids': 'Gitlab Gruppen Ids',

  'entity.auth.user.username': 'Benutzername',
  'entity.auth.user.username_canonical': 'Benutzername kanonisch',
  'entity.auth.user.password': 'Password',
  'entity.auth.user.email': 'E-Mail',
  'entity.auth.user.email_canonical': 'E-Mail kanonisch',
  'entity.auth.user.email_confirmed': 'E-Mail bestätigt',
  'entity.auth.user.enabled': 'Aktiviert',
  'entity.auth.user.super_admin': 'Super admin',
  'entity.auth.user.groups': 'Gruppen',
  'entity.auth.user.roles': 'Rollen',
  'entity.auth.user.last_login': 'Letzte Anmeldung',
  'entity.auth.user.confirmation_token': 'Bestätigungstoken',
  'entity.auth.user.confirmation_token_expires': 'Bestätigungstoken abgelaufen',
  'entity.auth.user.emailConfirmed': 'E-Mail bestätigt',

  'entity.ingame.ore_appearance.highest_altitude_from_surface': 'Höchste Höhe von der Oberfläche',
  'entity.ingame.ore_appearance.lowest_altitude_from_surface': 'Niedrigste Höhe von der Oberfläche',

  'entity.ingame.organization.state': 'Diplomatischer Zustand',
  'entity.ingame.organization.state_short': 'Zustand',
  'entity.ingame.organization.state.enemy': 'Feind',
  'entity.ingame.organization.state.neutral': 'Neutral',
  'entity.ingame.organization.state.friendly': 'Freundlich',
  'entity.ingame.organization.state.ally': 'Verbündet',
  'entity.ingame.organization.state.self': 'Selber',
  'entity.ingame.organization.ownerId': 'Besitzer Id',
  'entity.ingame.organization.foundedAt': 'Gegründet am',

  'entity.ingame.player.legate_in': 'Legate in',
  'entity.ingame.player.superlegate_in': 'Superlegate in',
  'entity.ingame.player.nq': 'Ist von Novaquark',
  'entity.ingame.player.about': 'Über',
  'entity.ingame.player.joined': 'Beitritt',
  'entity.ingame.player.founder_status': 'Founder Status',
  'entity.ingame.player.location': 'Standort',
  'entity.ingame.player.facebook': 'Facebook',
  'entity.ingame.player.twitter': 'Twitter',

  'entity.ingame.project.in_charge': 'Verantwortlicher',
  'entity.ingame.project.state': 'Status',
  'entity.ingame.project.contributors': 'Mitwirkende',
  'entity.ingame.project.access': 'Lesezugriff',
  'entity.ingame.project.deadline': 'Fälligkeit',
  'entity.ingame.project.state.concept': 'Konzept',
  'entity.ingame.project.state.in-progress': 'laufend',
  'entity.ingame.project.state.on-hold': 'wartend',
  'entity.ingame.project.state.testing': 'Testlauf',
  'entity.ingame.project.state.cancelled': 'abgebrochen',
  'entity.ingame.project.state.ready': 'fertig',

  'entity.ingame.storage.storage_content.minimum_amount': 'Mindestmenge',
  'entity.ingame.storage.storage_content.target_amount': 'Zielmenge',
  'entity.ingame.storage.storage_content.maximum_amount': 'Maximale Menge',
  'entity.ingame.storage.storage_content.resource_type_factor': 'Ressourcenart Faktor',

  'entity.player_progression.skill_level.stage': 'Stufe',
  'entity.player_progression.skill_level.required_points': 'Erforderliche Punkte',
  'entity.player_progression.skill_level.required_skill_level': 'Erforderliche Fähigkeitsstufe',

  'entity.community.quote.quote': 'Zitat',

  'entity.fal.file.filename': 'Dateiname',
  'entity.fal.file.path': 'Verzeichnis',
  'entity.fal.file.view_access': 'Zugriff anzeigen',
  'entity.fal.file.mod_access': 'Mod Zugriff',
  'entity.fal.file.is_listed': 'Ist aufgelistet',
  'entity.fal.file.owner': 'Eigentümer',

  'entity.change_log.entity': 'Objekt',
  'entity.change_log.entity_id': 'Objekt ID',
  'entity.change_log.changes': 'Änderungen',

  // messages
  'validation.too_short': 'Das Feld $1 muss mindestens $2 Zeichen beinhalten.',
  'validation.too_long': 'Das Feld $1 darf nicht mehr als $2 Zeichen beinhalten.',
  'validation.greater_than_zero': 'Die Eingabe $1 muss größer sein als 0.',
  'validation.vec3': 'Das Feld $1 muss so aussehen {"x":0.0,"y":0.0,"z":0.0}',
  'validation.cannot_be_empty': 'Das Feld $1 darf nicht leer sein.',

  // components
  'component.auth.confirm_email.title': 'E-Mail Bestätigen',
  'component.auth.confirm_email.explanation_1': 'Bitte überprüfe dein E-Mail-Konto. Du hast eine E-Mail mit einem Token erhalten. Gib dein Benutzernamen und dieses Token in die Felder unten ein und sende das Formular ab.',
  'component.auth.confirm_email.explanation_2': 'Wenn Du deine E-Mail aufgrund eines ungültigen Token nicht bestätigen kannst, ist das Token möglicherweise abgelaufen, da es nur 48 Stunden gültig ist. Wende dich in diesem Fall an einen Administrator, der die E-Mail erneut sendet und Dir ein neues Token generiert.',

  'component.auth.edit_role.title': 'Gruppe bearbeiten',
  'component.auth.edit_role.no_parent': '---nicht Übergeördnet---',

  'component.auth.edit_myself.title': 'Selber bearbeiten',
  'component.auth.edit_myself.dll_authentification': 'DLL Authentifizierung',
  'component.auth.edit_myself.copy_this_code': 'Kopiere diesen Code',
  'component.auth.edit_myself.copy_to_clipboard': 'Zwischenablage',
  'component.auth.edit_myself.avatar_url': 'Avatar url',
  'component.auth.edit_myself.new_password': 'Neues Passwort',
  'component.auth.edit_myself.new_password_repeat': 'Neues Passwort wiederholen',
  'component.auth.edit_myself.userdata_loaded': 'Benutzerdaten geladen',
  'component.auth.edit_myself.password_not_repeated': 'Du musst das Passwort wiederholen.',
  'component.auth.edit_myself.password_not_equal': 'Die Passwörter müssen gleich sein.',
  'component.auth.edit_myself.copied_to_clipboard': 'In die Zwischenablgage kopiert.',
  'component.auth.edit_myself.preferredLanguage': 'Bevorzugte Sprache',

  'component.auth.edit_user.title': 'Benutzer bearbeiten',
  'component.auth.edit_user.password_not_repeated': 'Du musst das Passowrt wiederholen.',
  'component.auth.edit_user.password_repetition_not_equal': 'Die Passwörter müssen gleich sein.',
  'component.auth.edit_user.email_not_valid': 'Dies muss eine gültige E-Mail-Adresse sein',

  'component.auth.login.login': 'Login',
  'component.auth.login.register': 'Registrieren',
  'component.auth.login.confirm_email': 'Bestätigungs-E-Mail',
  'component.auth.login.welcome': 'Willkommen',
  'component.auth.login.account_activated': 'Du hast alles erledigt. Dein Account ist aktiv und du kannst dich anmelden. Solltest du Probleme haben, melde dich bei WildChild85 für direkten Support.',

  'component.auth.register.title': 'Registration',

  'component.auth.select_avatar.title': 'Benutzerbild auswählen',
  'component.auth.select_avatar.filetype_not_supported': 'Dieser Dateityp wird nicht unterstützt.',

  'component.community.common.user_browser.search_first': 'Bitte erst nach Benutzern suchen.',
  'component.community.common.group_browser.search_first': 'Bitte erst nach Gruppen suchen.',

  'component.community.edit_quote.title': 'Zitat bearbeiten',

  'component.crafting_calculator.total_crafting_time': 'Gesamte Herstellungszeit',
  'component.crafting_calculator.total_ore_sell_value': 'Gesamter Erz Verkauswert',
  'component.crafting_calculator.total_ore_buy_value': 'Gesamter Erz Einkaufswert',
  'component.crafting_calculator.total_ore_bot_value': 'Gesamter Erz Botwert',
  'component.crafting_calculator.total_mass': 'Gesamtes Gewicht',
  'component.crafting_calculator.close_factory': 'Fabrik schließen',
  'component.crafting_calculator.endproducts': 'Endprodukte',
  'component.crafting_calculator.amount': 'Menge',
  'component.crafting_calculator.ingredients_parts': 'Bestandteile (Parts)',
  'component.crafting_calculator.ingredients_materials': 'Bestandteile (Materials)',
  'component.crafting_calculator.add_end_product': 'Endprodukt hinzufügen',
  'component.crafting_calculator.item_data': 'Datenblatt',
  'component.crafting_calculator.ingredients': 'Bestandteile',
  'component.crafting_calculator.craftable_in': 'Herstellbar in',
  'component.crafting_calculator.usages': 'Verwendungen',
  'component.crafting_calculator.calculate': 'Berechnen',

  'component.files.create_dir.title': 'Verzeichnis erstellen',

  'component.files.edit_file.title': 'Datei bearbeiten',

  'component.files.file_browser.navigate_up': 'Navigiere nach oben',
  'component.files.file_browser.switch_to_tiles': 'Wechsel in den Anzeigemodus Kacheln',
  'component.files.file_browser.switch_to_list': 'Wechsel in den Anzeigemodus Listen',
  'component.files.file_browser.show_gallery_mode': 'Galeriemodus anzeigen',
  'component.files.file_browser.upload_files': 'Daten hochladen',
  'component.files.file_browser.create_dir': 'Erstelle ein Verzeichniss',
  'component.files.file_browser.open_folder': 'Ordner öffnen',
  'component.files.file_browser.view_file': 'Datei ansehen',
  'component.files.file_browser.open_file': 'Datei im neuen Tab öffnen',
  'component.files.file_browser.edit_file': 'Datei bearbeiten',
  'component.files.file_browser.delete_file': 'Datei löschen',
  'component.files.file_browser.show_file_actions_menu': 'Show file actions menu',
  'component.files.file_browser.hide_file_actions_menu': 'Hide file actions menu',
  'component.files.file_browser.apply': 'Übernehmen',

  'component.files.upload_files.title': 'Daten hochladen',
  'component.files.upload_files.instruction': 'Klicke hier, um die Dateien auszuwählen, die Du hochladen möchtest. Klicke rechts auf Upload, um den Upload zu starten.',
  'component.files.upload_files.start_upload': 'Hochladen starten',
  'component.files.upload_files.optimization_explanation': 'Bilddateien (z. B. PNG, JPG, SVG, GIF) werden automatisch optimiert, um die Dateigröße zu verringern. Dies kann bis zu 2 Minuten dauern. Bitte warte, bis die Upload-Leiste grün oder rot wird.',
  'component.files.upload_files.optimizing': 'Optimiere',
  'component.files.upload_files.file_too_big': 'Die Datei $1 ist größerals das Limit von 5gb.',
  'component.files.upload_files.pending': 'Steht aus.',
  'component.files.upload_files.uploading': 'Hochladen.',

  'component.ingame.common.item_browser.title': 'Wähle ein Item aus',
  'component.ingame.common.item_browser.select_item_first': 'Bitte erst nach items suchen.',

  'component.ingame.common.ore_browser.title': 'Erz auswählen',
  'component.ingame.common.ore_browser.select_ore_first': 'Bitte erst nach Erzen suchen.',

  'component.ingame.edit_item.edit_recipes.add_ingredient': 'Bestandteil hinzufügen',
  'component.ingame.edit_item.edit_recipes.save_first': 'Bitte das Item erst speichern. Es muss eine ID haben.',

  'component.ingame.edit_item.original_item.title': 'Originales Item',
  'component.ingame.edit_item.original_item.save_first': 'Bitte das Item erst speichern.',

  'component.ingame.edit_scan_result.scan_result_resources.title': 'Ressourcen für Scanergebnisse',
  'component.ingame.edit_scan_result.scan_result_resources.add_scan_result_resources': 'Hinzufügen von Ressourcen für Scanergebnisse',
  'component.ingame.edit_scan_result.scan_result_resources.save_item_first': 'Bitte speicher zuerst das Scan-Ergebnis. Es muss eine ID haben.',

  'component.ingame.edit_scan_result.save_first': 'Bitte speicher zuerst das Scan-Ergebnis.',

  'component.ingame.edit_stellar_object.edit_ore_appearances.title': 'Erzerscheinungen',
  'component.ingame.edit_stellar_object.edit_ore_appearances.add_ore_appearances': 'Erzerscheinungen hinzufügen',
  'component.ingame.edit_stellar_object.edit_ore_appearances.save_first': 'Bitte speicher zuerst den Himmelskärper. Es muss eine ID haben.',

  'component.ingame.edit_stellar_object.origninal_stellar_object.title': 'Original Himmelskörper',
  'component.ingame.edit_stellar_object.origninal_stellar_object.save_first': 'Bitte speicher zuerst den Himmelskärper.',

  'component.ingame.edit_group.title': 'Gruppe bearbeiten',

  'component.ingame.edit_item.title': 'Item bearbeiten',

  'component.ingame.edit_organization.title': 'Organisation bearbeiten',

  'component.ingame.edit_player.title': 'Spieler bearbeiten',

  'component.ingame.edit_scan_result.title': 'Scanergebnis bearbeiten',

  'component.ingame.edit_section.title': 'Sektion bearbeiten',

  'component.ingame.edit_stellar_object.title': 'Himmelskörper bearbeiten',

  'component.ingame.edit_storage.title': 'Lager bearbeiten',

  'component.ingame.edit_storage_content.title': 'Lagerinhalt bearbeiten',

  'component.ingame.project.title.create': 'Erstelle ein neues Projekt',
  'component.ingame.project.title.edit': 'Bearbeite das Projekt',
  'component.ingame.project.title.view': 'Projektansicht',
  'component.ingame.project.project_properties.title': 'Projekteigenschaften',
  'component.ingame.project.project_images.title': 'Projektbilder',

  'component.ingame.edit_project_type.title': 'Projekttyp bearbeiten',

  'component.ingame.select_project_images.title': 'Projektbilder auswählen',

  'component.player_progression.edit_skill.edit_required_skill_level.title': 'Wähle die Fähigkeitsstufe',
  'component.player_progression.edit_skill.edit_required_skill_level.search_first': 'Bitte suche zuerst nach Fähigkeiten.',

  'component.player_progression.edit_skill.edit_skill_levels.add_skill_level': 'Fertigkeitsstufe hinzufügen',
  'component.player_progression.edit_skill.edit_skill_levels.save_first': 'Bitte speicher zuerst die Fähigkeit. Es muss eine ID haben.',

  'component.player_progression.common.skill_group_browser.title': 'Skill Gruppe auswählen',
  'component.player_progression.common.skill_group_browser.select_skill_group_first': 'Bitte zuerst nach Skill Gruppen suchen.',

  'component.player_progression.edit_skill.original_skill.title': 'Original Fähigkeit',
  'component.player_progression.edit_skill.original_skill.save_first': 'Bitte speicher zuerst die Fähigkeit',

  'component.player_progression.skill_details.skill_details.required_talent_points': 'Erforderliche Talentpunkte',
  'component.player_progression.skill_details.skill_details.learning_time_queued': 'Lernzeit in der Warteschlange',
  'component.player_progression.skill_details.skill_details.learning_time_unqueued': 'Lernzeit in Warteschlange',
  'component.player_progression.skill_details.skill_details.unlocks_skill_levels': 'Schaltet Fertigkeitsstufen frei',
  'component.player_progression.skill_details.skill_details.unlocked_by': 'Entsperrt von',

  'component.player_progression.edit_skill.title': 'Fähigkeit bearbeiten',

  'component.player_progression.edit_skill_group.title': 'Fertigkeitsgruppe bearbeiten',

  'view.auth.roles.title': 'Zugriff - Roles',

  'view.auth.users.title': 'Zugriff - Benutzer',
  'view.auth.users.resend_confirmation_email': 'Bestätigungsmail erneut senden',
  'view.auth.users.enable_user': 'Benutzer aktivieren',
  'view.auth.users.disable_user': 'Benutzer deaktivieren',

  'view.community.member.title': 'Community - Mitglieder',
  'view.community.member.copy_section_leaders': 'Kopiere Bereichsleiter',
  'view.community.member.copy_leaders': 'Kopiere Führung',
  'view.community.member.copy_members': 'Kopiere Mitglieder',
  'view.community.member.copy_section_leaders_to_clipboard': 'Kopiere Bereichsleiter in die Zwischenablage',
  'view.community.member.copy_leaders_to_clipboard': 'Kopiere Führung in die Zwischenablage',
  'view.community.member.copy_members_to_clipboard': 'Kopiere Mitglieder in die Zwischenablage',
  'view.community.member.section_leaders_copied': 'Bereichsleiter in die Zwischenablage kopiert.',
  'view.community.member.leaders_copied': 'Führung in die Zwischenablage kopiert.',
  'view.community.member.members_copied': 'Mitglieder in die Zwischenablage kopiert.',

  'view.community.organization_chart.title': 'Community - Organigramm',

  'view.community.quotes.title': 'Community - Zitate',
  'view.community.quotes.delete_quote': 'Zitat löschen',

  'view.ingame.crafting_calculator.title': 'Crafting Calculator',
  'view.ingame.crafting_calculator.show_factory': 'Zeige Fabrik',
  'view.ingame.crafting_calculator.everything loaded': 'Items, Gruppen, Sektionen, Rezepte wurden neu geladen',

  'view.ingame.constructs.title': 'Ingame - Konstrukte',
  'view.ingame.constructs.filter_by_organizations': 'Filter nach Organisation',
  'view.ingame.constructs.player_id_owner': 'Spieler Id (Besitzer)',
  'view.ingame.constructs.player_name_owner': 'Spieler Name (Besitzer)',
  'view.ingame.constructs.delete_construct': 'Konstrukt löschen',
  'view.ingame.constructs.copy_coordinates': 'Koordinaten kopieren',
  'view.ingame.constructs.show_construct_logs': 'Konstrukt Logs anzeigen',

  'view.ingame.groups.title': 'Ingame - Gruppen',
  'view.ingame.groups.delete_group': 'Gruppen löschem',

  'view.ingame.items.title': 'Ingame - Items',
  'view.ingame.items.delete_item': 'Item löschen',

  'view.ingame.ore_appearances.title': 'Ingame - Erzerscheinungen',

  'view.ingame.organizations.title': 'Ingame - Organisationen',
  'view.ingame.organizations.open_community_page': 'Öffne die Organisations Community-Seite',
  'view.ingame.organizations.delete_org': 'Organisationen löschen',
  'view.ingame.organizations.show_organization_logs': 'Organisations-Logs anzeigen',

  'view.ingame.players.title': 'Ingame - Spieler',
  'view.ingame.players.filter_by_organization': 'Filter nach Organisation',
  'view.ingame.players.open_community_page': 'Öffne Spieler Community-Seite',
  'view.ingame.players.delete_player': 'Spieler löschen',

  'view.ingame.scan_result.title': 'Ingame - Scan-Ergebnisse',
  'view.ingame.players.delete_scan_result': 'Scan-Ergebnisse löschen',

  'view.ingame.sections.title': 'Ingame - Sektionen',
  'view.ingame.players.delete_section': 'Sektionen löschen',

  'view.ingame.stellar_objects.title': 'Ingame - Himmelsobjekte',
  'view.ingame.players.delete_stellar_object': 'Himmelsobjekte löschen',

  'view.ingame.skill_tree.title': 'Spieler-Fortschritt - Fähigkeitenbaum',
  'view.ingame.skill_tree.invested_skill_points': 'Investierte Fähigkeitspunkte',
  'view.ingame.skill_tree.toggle_edit_mode': 'Bearbeitungsmodus umschalten',
  'view.ingame.skill_tree.add_skill_group': 'Füge eine neue SFähigkeitsgruppe hinzu',
  'view.ingame.skill_tree.add_skill': 'Füge eine neue Fähigkeit hinzu',
  'view.ingame.skill_tree.edit_skill_group': 'Bearbeite diese Fähigkeitsgruppe',
  'view.ingame.skill_tree.delete_skill_group': 'Bearbeite diese Fähigkeitsgruppe',
  'view.ingame.skill_tree.edit_skill': 'Bearbeite diese Fähigkeit',
  'view.ingame.skill_tree.delete_skill': 'Lösche diese Fähigkeit',
  'view.ingame.skill_tree.unlock_skill_level': 'Schalt Fähigkeitsstufe frei',
  'view.ingame.skill_tree.lock_skill_level': 'Fertigkeitsstufe sperren',
  'view.ingame.skill_tree.show_skill_details': 'Zeige die Fähigkeiten-Details an',
  'view.ingame.skill_tree.no_players_assigned': 'Kein Spieler verknüpft',
  'view.ingame.skill_tree.click_for_required': 'Klicken um den vorausgesetzten Skill-Level zu sehen',
  'view.ingame.skill_tree.click_to_unlock': 'Klicken zum Freischalten',
  'view.ingame.skill_tree.click_to_lock': 'Klicken um Freischaltung zu entfernen',
  'view.ingame.skill_tree.unlocked': 'Skill-Level freigeschaltet',
  'view.ingame.skill_tree.locked': 'Skill-Level Freischaltung entfernt',

  'view.ingame.statistics.title': 'Ingame Statistiken'
}
