// Register a global custom directive called `v-focus`
export default {
  // When the bound container is inserted into the DOM...
  inserted: function (el) {
    // Focus the container
    el.addEventListener('click', (event) => {
      if (el.style.position !== 'absolute' && el.style.position !== 'relative' && el.style.position !== 'fixed') {
        el.style.position = 'relative';
      }
      const container = document.createElement('span');
      container.classList.add('slash-effect__container');
      // container.style.left = el.offsetLeft + 'px';
      // container.style.top = el.offsetTop + 'px';
      // container.style.height = el.offsetHeight + 'px';
      // container.style.width = el.offsetWidth + 'px';

      const slashLeft = document.createElement('span');
      const slashRight = document.createElement('span');
      slashLeft.classList.add('slash-effect__slash');
      slashLeft.classList.add('slash-effect__slash--left');
      slashRight.classList.add('slash-effect__slash');
      slashRight.classList.add('slash-effect__slash--right');

      container.append(slashLeft);
      container.append(slashRight);

      el.append(container);
      setTimeout(() => container.remove(), 500);
    });
  }
};