import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue');
const Users = () => import('./views/Auth/Users.vue');
const Roles = () => import('./views/Auth/Roles.vue');
const IngameGroups = () => import('./views/InGame/Groups');
const IngameItems = () => import('./views/InGame/Items');
const IngameItemReports = () => import('./views/InGame/ItemReports');
const StellarObjects = () => import('./views/InGame/StellarObjects');
const OreAppearances = () => import('./views/InGame/OreAppearances');
const Organizations = () => import('./views/InGame/Organizations');
const Players = () => import('./views/InGame/Players');
const Constructs = () => import('./views/InGame/Constructs');
const CdnBrowser = () => import('./views/Cdn/Browser');
const SkillTree = () => import('./views/PlayerProgression/SkillTree');
const CraftingCalculator = () => import('./views/CraftingCalculator/Calculator');
const Member = () => import('./views/Community/Member');
const ScanResults = () => import("./views/InGame/ScanResults");
const Projects = () => import('./views/InGame/Projects');
const Git = () => import('./views/Git');
const GitAuth = () => import('./views/GitAuth');
const DllVersions = () => import('./views/Auth/DllVersions');
const HardwareIds = () => import('./views/Auth/HardwareIds');
const DllLogs = () => import("./views/Auth/DllLogs");
const PrivacyPolicy = () => import("./views/Misc/PrivacyPolicy");
const OrganizationMembershipLogs = () => import("./views/InGame/OrganizationMembershipLogs");
const Credits = () => import("./views/Misc/Credits");
const OreAppearanceReports = () => import("./views/InGame/OreAppearanceReports");
const ImportedItems = () => import("./views/InGame/ImportedItems/ImportedItems");
const CraftingLogs = () => import("./views/InGame/CraftingLogs");
const VoxelLibrary = () => import("./views/InGame/VoxelLibrary");
const ItemVariationLibrary = () => import("./views/InGame/ItemVariationLibrary");
const UserStatistics = () => import("./views/Auth/UserStatistics");
const Project = () => import("./views/InGame/Project");
const PlayerRegistrationLogs = () => import("./views/InGame/PlayerRegistrationLogs");
const HyperionWrapper = () => import("./views/Community/HyperionWrapper");
const Ships = () => import("./views/InGame/Expo/Ships");
const Ship = () => import("./views/InGame/Expo/Ship");
const ShipClasses = () => import("./views/InGame/Expo/ShipClasses");
const LuaExport = () => import("./views/InGame/LuaExport");
const DiscordAuthorizeRedirect = () => import("./views/Discord/DiscordAuthorizeRedirect");
const DiscordHandleAuthorization = () => import("./views/Discord/DiscordHandleAuthorization");
const Login = () => import("./components/Auth/Login");
const DiscordHandleAuthorizationConnect = () => import("./views/Discord/DiscordHandleAuthorizationConnect");
const DiscordAuthorizeRedirectConnect = () => import("./views/Discord/DiscordAuthorizeRedirectConnect");
const ItemdumpViewer = () => import("./views/InGame/ImportedItems/ItemdumpViewer");
const Todos = () => import("./views/Community/Todos");

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard,
      meta: { requiresAuth: true }
    },
    {
      path: '/discord-redirect',
      name: 'discord-redirect',
      component: DiscordAuthorizeRedirect,
      meta: { requiresAuth: false }
    },
    {
      path: '/discord-handle',
      name: 'discord-handle',
      component: DiscordHandleAuthorization,
      meta: { requiresAuth: false }
    },
    {
      path: '/discord-redirect-connect',
      name: 'discord-redirect-connect',
      component: DiscordAuthorizeRedirectConnect,
      meta: { requiresAuth: true }
    },
    {
      path: '/discord-handle-connect',
      name: 'discord-handle-connect',
      component: DiscordHandleAuthorizationConnect,
      meta: { requiresAuth: true }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { requiresAuth: false }
    },
    {
      path: '/git',
      name: 'git',
      component: Git,
      meta: { requiresAuth: true, requiredRoles: ['Member'] }
    },
    {
      path: '/git-auth',
      name: 'git_auth',
      component: GitAuth,
      meta: { requiresAuth: true, requiredRoles: ['Member'] }
    },
    {
      path: '/auth-users',
      name: 'auth_users',
      component: Users,
      meta: { requiresAuth: true, requiredRoles: ['Administrator'] }
    },
    {
      path: '/auth-roles',
      name: 'auth_roles',
      component: Roles,
      meta: { requiresAuth: true, requiredRoles: ['Administrator'] }
    },
    {
      path: '/auth-dll-versions',
      name: 'auth_dll-versions',
      component: DllVersions,
      meta: { requiresAuth: true, requiredRoles: ['Administrator'] }
    },
    {
      path: '/auth-hardware-ids',
      name: 'auth_hardware-ids',
      component: HardwareIds,
      meta: { requiresAuth: true, requiredRoles: ['Administrator'] }
    },
    {
      path: '/auth-dll-logs',
      name: 'auth_dll-logs',
      component: DllLogs,
      meta: { requiresAuth: true, requiredRoles: ['Administrator'] }
    },
    {
      path: '/ingame-groups',
      name: 'ingame_groups',
      component: IngameGroups,
      meta: { requiresAuth: true, requiredRoles: ['Ingame Items Editor'] }
    },
    {
      path: '/ingame-items',
      name: 'ingame_items',
      component: IngameItems,
      meta: { requiresAuth: true, requiredRoles: ['Ingame Items Editor'] }
    },
    {
      path: '/ingame-item-reports',
      name: 'ingame_item_reports',
      component: IngameItemReports,
      meta: { requiresAuth: true }
    },
    {
      path: '/ingame-imported-items',
      name: 'ingame_imported_items',
      component: ImportedItems,
      meta: { requiresAuth: true, requiredRoles: ['Administrator'] }
    },
    {
      path: '/ingame-itemdump-viewer',
      name: 'ingame_itemdump_viewer',
      component: ItemdumpViewer,
      meta: { requiresAuth: true, requiredRoles: ['Administrator'] }
    },
    {
      path: '/ingame-stellar-objects',
      name: 'ingame_stellar_objects',
      component: StellarObjects,
      meta: { requiresAuth: true, requiredRoles: ['Ingame Items Editor'] }
    },
    {
      path: '/ingame-scan-results',
      name: 'ingame_scan_results',
      component: ScanResults,
      meta: { requiresAuth: true, requiredRoles: ['Member'] }
    },
    {
      path: '/ingame-organizations',
      name: 'ingame_organizations',
      component: Organizations,
      meta: { requiresAuth: true, requiredRoles: ['Member', 'Allied Legate'] }
    },
    {
      path: '/ingame-players',
      name: 'ingame_players',
      component: Players,
      meta: { requiresAuth: true, requiredRoles: ['Member', 'Allied Legate'] }
    },
    {
      path: '/ingame-player-registration-logs',
      name: 'ingame_player_registration_logs',
      component: PlayerRegistrationLogs,
      meta: { requiresAuth: true }
    },
    {
      path: '/ingame-constructs',
      name: 'ingame_constructs',
      component: Constructs,
      meta: { requiresAuth: true, requiredRoles: ['Member'] }
    },
    {
      path: '/ingame-organization-membership-logs',
      name: 'ingame_organization_membership_logs',
      component: OrganizationMembershipLogs,
      meta: { requiresAuth: true, requiredRoles: ['Member', 'Allied Legate'] }
    },
    {
      path: '/ingame-projects',
      name: 'ingame_projects',
      component: Projects,
      meta: { requiresAuth: true, requiredRoles: ['Member'] }
    },
    {
      path: '/ingame-project/:id',
      name: 'ingame_project',
      component: Project,
      meta: { requiresAuth: true, requiredRoles: ['Member'] }
    },
    {
      path: '/ingame-expo-ships',
      name: 'ingame_ships',
      component: Ships,
      meta: { requiresAuth: true }
    },
    {
      path: '/ingame-expo-ship-classes',
      name: 'ingame_ship_classes',
      component: ShipClasses,
      meta: { requiresAuth: true, requiredRoles: ['Administrator'] }
    },
    {
      path: '/ingame-expo-ship/:id',
      name: 'ingame_ship',
      component: Ship,
      meta: { requiresAuth: true }
    },
    {
      path: '/auth-statistics',
      name: 'auth_statistics',
      component: UserStatistics,
      meta: { requiresAuth: true }
    },
    {
      path: '/playerprogression-skilltree',
      name: 'playerprogression_skilltree',
      component: SkillTree,
      meta: { requiresAuth: true }
    },
    {
      path: '/file-browser',
      name: 'file_browser',
      component: CdnBrowser,
      meta: { requiresAuth: true }
    },
    {
      path: '/crafting-calculator',
      name: 'crafting_calculator',
      component: CraftingCalculator,
      meta: { requiresAuth: true }
    },
    {
      path: '/ingame-lua-export',
      name: 'ingame_lua_export',
      component: LuaExport,
      meta: { requiresAuth: true }
    },
    {
      path: '/crafting-calculator-logs',
      name: 'crafting_calculator_logs',
      component: CraftingLogs,
      meta: { requiresAuth: true, requiredRoles: ['Bereichsleiter'] }
    },
    {
      path: '/ingame-ore-appearances',
      name: 'ore_appearances',
      component: OreAppearances,
      meta: { requiresAuth: true }
    },
    {
      path: '/ingame-ore-appearance-reports',
      name: 'ore_appearance_reports',
      component: OreAppearanceReports,
      meta: { requiresAuth: true }
    },
    {
      path: '/ingame-voxel-library',
      name: 'voxel_library',
      component: VoxelLibrary,
      meta: { requiresAuth: true }
    },
    {
      path: '/ingame-item-variation-library',
      name: 'item_variation_library',
      component: ItemVariationLibrary,
      meta: { requiresAuth: true }
    },
    {
      path: '/community/hyperion',
      name: 'hyperion',
      component: HyperionWrapper,
      meta: { requiresAuth: true }
    },
    {
      path: '/community/member',
      name: 'member',
      component: Member,
      meta: { requiresAuth: true, requiredRoles: ['Member'] }
    },
    {
      path: '/community/todos',
      name: 'todos',
      component: Todos,
      meta: { requiresAuth: true, requiredRoles: ['Member'] }
    },
    {
      path: '/privacy-policy',
      name: 'privacy_policy',
      component: PrivacyPolicy,
      meta: { requiresAuth: false }
    },
    {
      path: '/credits',
      name: 'credits',
      component: Credits,
      meta: { requiresAuth: false }
    },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    // }
  ],
});
router.beforeEach((to, from, next) => {
  if (!store.state.user) {
    store.commit('setUser');
  }
  if (to.meta.requiresAuth) {
    if (!store.state.user) {
      next('/login');
    }
    if(to.meta.requiredRoles) {
      if(store.getters.hasOneRoles(to.meta.requiredRoles)) {
        next();
      } else {
        next('/');
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
