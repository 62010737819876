<template>
  <div class="pagination" :class="{'pagination--disabled': disabled}">
    <div class="pagination__page pagination__page--prev" :class="{'pagination__page--disabled': page <= 1}" @click="paginatePrev" v-slashes></div>
    <div class="pagination__page" v-for="pageItem in pages" :class="pageClass(pageItem)" @click="paginate(pageItem)" v-slashes>{{pageItem}}</div>
    <div class="pagination__page pagination__page--next" :class="{'pagination__page--disabled': page >= countPages}" @click="paginateNext" v-slashes></div>
  </div>
</template>

<script>
  export default {
    name: "Pagination",
    props: {
      countPages: Number,
      totalVisible: {
        type: Number,
        default: 9
      },
      page: {
        type: Number,
        default: 1
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      maxButtons: 0
    }),
    computed: {
      pages() {
        const maxlength = parseInt(this.totalVisible, 10);
        if (this.countPages <= maxlength) {
          return this.range(1, this.countPages);
        }

        const even = maxlength % 2 === 0 ? 1 : 0;
        const left = Math.floor(maxlength / 2);
        const right = this.countPages - left + 1 + even;

        if (this.page > left && this.page < right) {
          const start = this.page - left + 2;
          const end = this.page + left - 2 - even;
          return [1, '...', ...this.range(start, end), '...', this.countPages]
        } else if (this.page === left) {
          const end = this.page + left - 1 - even;
          return [...this.range(1, end), '...', this.countPages]
        } else if (this.page === right) {
          const start = this.page - left + 1;
          return [1, '...', ...this.range(start, this.countPages)]
        } else {
          return [
            ...this.range(1, left),
            '...',
            ...this.range(right, this.countPages),
          ]
        }
      },
    },
    methods: {
      pageClass(page) {
        return {
          'pagination__page--filler': !parseInt(page, 10),
          'pagination__page--active': page === this.page
        }
      },
      paginate(page) {
        this.$emit('paginate', page);
      },
      paginatePrev() {
        this.paginate(this.page - 1);
      },
      paginateNext() {
        this.paginate(this.page + 1);
      },
      range(from, to) {
        const range = [];

        from = from > 0 ? from : 1;

        for (let i = from; i <= to; i++) {
          range.push(i)
        }

        return range
      },
    }
  }
</script>

<style scoped>

</style>