export const notificationsMixin = {
  methods: {
    showLoveNotification(message) {
      this.$store.dispatch('addNotification', {
        message,
        type: 'love',
      });
    },
    showSuccessNotification(message) {
      this.$store.dispatch('addNotification', {
        message,
        type: 'success',
      });
    },
    showInfoNotification(message) {
      if (process.env.NODE_ENV !== 'production') {
        this.$store.dispatch('addNotification', {
          message,
          type: 'info',
        });
      }
    },
    showWarningNotification(message) {
      this.$store.dispatch('addNotification', {
        message,
        type: 'warning',
      });
    },
    showErrorNotifications(error) {
      let errors = [];
      console.log(error.response);
      if (error.response !== undefined) {
        if (error.status === 401) {
          errors.push('Wrong username or password.')

        } else if (error.status === 403) {
          errors.push('You don\'t have access to this area.')

        } else if (error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length === undefined) {
            Object.keys(error.response.data.errors).forEach(errorKey => {
              let currentError = error.response.data.errors[errorKey];
              if (typeof currentError === 'string') {
                errors.push(currentError);
              } else if (currentError.length !== undefined) {
                currentError.forEach(currentErrorMessage => errors.push(`[${errorKey}] ${currentErrorMessage}`));
              } else if ('description' in currentError) {
                errors.push(currentError.description);
              } else if (typeof currentError === 'object') {
                Object.keys(currentError).forEach(internalErrorKey => {
                  let internalError = currentError[internalErrorKey];
                  if (typeof internalError === 'string') {
                    errors.push(`[${internalErrorKey}] ${internalError}`)
                  } else if (internalError.length !== undefined) {
                    errors.push(internalError.forEach(internalErrorMessage => {
                        errors.push(`[${internalErrorKey}] ${internalErrorMessage}`);
                      }
                    ));
                  }
                })
              } else {
                errors.push(JSON.stringify(currentError));
              }
            });
          } else if (error.response.data.errors && error.response.data.errors.length > 0) {
            errors.push(error.response.data.errors.map((currentError) => {
              if (typeof currentError === 'string') {
                return currentError;
              } else if (currentError.length !== undefined) {
                return currentError;
              } else if ('description' in currentError) {
                return currentError.description;
              } else {
                return JSON.stringify(currentError);
              }
            }));
          } else if (error.response.data.length) {
            error.response.data.forEach(responseError => {
              errors.push(responseError.description);
            })
          } else {
            errors.push(error.response.data.title);
          }
        } else {
          errors.push(error);
        }
      } else {
        errors.push(error);
      }
      errors.forEach((errorMsg) => {
        this.$store.dispatch('addNotification', {
          message: errorMsg,
          type: 'error'
        });
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('clearNotifications');
    next();
  }
};