<template>
  <div class="dialog" ref="dialogContent" :class="{'dialog--open': show, 'dialog--fullscreen': fullscreen }">
    <transition name="dialogfade" duration="300">
      <div class="dialog__overlay" v-if="show && !fullscreen" :key="'overlay'" @click="close"></div>
    </transition>
    <transition name="dialogzoom" duration="300">
      <div class="dialog__content" :style="{'max-width': maxWidth}" v-if="show" :key="'content'">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>

  export default {
    name: "MyDialog",
    props: {
      show: {
        type: Boolean,
        default: false
      },
      persistent: {
        type: Boolean,
        default: true
      },
      fullscreen: {
        type: Boolean,
        default: false
      },
      maxWidth: {
        type: String,
        default: '80vw'
      }
    },
    data: () => ({}),
    methods: {
      close() {
        if (!this.persistent) {
          this.$emit('close')
        }
      }
    },
    beforeMount() {
      this.$nextTick(() => {
        const target = document.getElementById('dialogs');
        target.appendChild(
          this.$refs.dialogContent
        )
      })
    },
  }
</script>