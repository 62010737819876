<template>
  <my-dialog :show="showDialog" max-width="600px">
    <div class="card">
      <div class="card__header">
        <div class="card__title">{{title}}</div>
        <div class="card__button" @click="closeDialog" v-slashes>
          <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
        </div>
      </div>
      <progress-linear v-if="loading"/>
      <div class="card__content">
        <div class="control-bar control-bar--no-h-space" :class="{'control-bar--disabled': loading}">
          <text-field
              class="control-bar__search"
              v-model="searchTerm"
              :label="__('common.search_term')"
              max-length="50"
              :hide-details="true"
              :clearable="true"
              @keyup:enter="refreshDelayed(100)"
              @click:clear="refreshDelayed(100)"
          />
          <div class="control-bar__buttons">
            <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
              <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
                   alt="refresh"/>
            </div>
          </div>
        </div>
        <pagination
            class="mt"
            :count-pages="pagination.pages"
            :total-visible="5"
            :page="pagination.page"
            :disabled="loading"
            @paginate="paginate"
        />
        <div class="selection-list mt" v-if="availableItems.length > 0">
          <div class="selection-list__item" v-for="item in availableItems" v-slashes @click="emitSelection(item)">
            <template v-if="getIcon(item)">
              <div class="selection-list__item-icon" v-if="isUri(getIcon(item))">
                <img :src="getIcon(item)" alt="-"/>
              </div>
              <div v-else class="selection-list__item-icon" v-html="getIcon(item)"></div>
            </template>
            <div class="selection-list__item-title">
              {{ getTitle(item) }}
            </div>
          </div>
        </div>
        <pagination
            class="mt"
            :count-pages="pagination.pages"
            :total-visible="5"
            :page="pagination.page"
            :disabled="loading"
            @paginate="paginate"
        />
      </div>
    </div>
  </my-dialog>
</template>

<script>
  import apiSecured from '../../api/secured'
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { paginationMixin } from "../../mixins/pagination";

  export default {
    name: "SelectReference",
    mixins: [notificationsMixin, localizationMixin, paginationMixin],
    props: {
      title: {
        type: String,
        default: 'Universal browser',
      },
      endpoint: {
        type: String,
        default: null
      },
      fieldTitle: {
        type: String,
        default: 'title'
      },
      fieldIcon: {
        type: String,
        default: 'icon'
      }
    },
    data: () => ({
      skipCreated: true,
      loading: false,
      availableItems: [],
      searchTerm: '',
      showDialog: false
    }),
    computed: {
      fieldClasses() {
        return {
          'select-reference--error': this.errorMessages.length > 0,
          'select-reference--hide-info': this.hideInfo
        }
      },
      joinedErrorMessages() {
        return this.errorMessages.join('<br/>');
      }
    },
    methods: {
      isUri(value) {
        if(!value) {
          return false;
        }
        return value.substr(0,4).toLocaleLowerCase() === 'http'
      },
      getValue(item) {
        if (item && typeof item === 'object') {
          return item[this.fieldValue];
        }
        return item;
      },
      getTitle(item) {
        if (item && typeof item === 'object') {
          return item[this.fieldTitle];
        }
        return item;
      },
      getIcon(item) {
        if (item && typeof item === 'object') {
          return item.hasOwnProperty(this.fieldIcon) ? item[this.fieldIcon] : null;
        }
        return '';
      },
      openDialog() {
        this.showDialog = true;
        this.refresh();
      },
      closeDialog() {
        this.showDialog = false;
      },
      emitSelection(item) {
        this.$emit('itemSelected', item);
        this.closeDialog();
      },
      refreshDelayed(delay) {
        setTimeout(this.refresh, delay);
      },
      refresh() {
        if (!this.endpoint) {
          this.showErrorNotifications('You have to provide an "endpoint". Where should the data come from huh? From god?');
          return;
        }
        this.loading = true;
        apiSecured.get(this.endpoint, {
          params: this.getPaginationParams()
        }).then((res) => {
          this.availableItems = [];
          this.availableItems = res.data;
          this.updatePagination(res);
          this.loading = false;
          this.showInfoNotification(this.__('common.data_loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      }
    },
  }
</script>