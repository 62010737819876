<template>
  <div class="confirm-email">
    <my-dialog :show="showMe" @close="close" max-width="400px">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{ __('component.auth.confirm_email.title') }}</div>
          <div class="card__button" v-slashes @click="close"><img
              src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/></div>
        </div>
        <progress-linear v-if="loading" :indeterminate="true"/>
        <div class="card__content">
          <div class="layout layout--wrap">
            <div class="flex xs12 mt">
              <p>{{ __('component.auth.confirm_email.explanation_1') }}</p>
              <p>{{ __('component.auth.confirm_email.explanation_2') }}</p>
            </div>
            <div class="flex xs12 mt">
              <text-field
                  v-model="user.username"
                  :label="__('entity.auth.user.username')"
                  :error-messages="errors.username"
                  maxlength="50"
                  :hint="__('common.not_email_username')"
                  @keyup.enter="confirmEmail"
              />
            </div>
            <div class="flex xs12">
              <text-field
                  v-model="user.confirmationToken"
                  :label="__('entity.auth.user.confirmation_token')"
                  :error-messages="errors.confirmationToken"
                  maxlength="300"
                  :hint="__('common.hint_confirmation_token')"
                  @keyup.enter="confirmEmail"
              />
            </div>
            <div class="flex xs6">
              <div class="btn btn--success" :loading="loading" :disabled="user.confirmationToken.length < 200"
                   @click="confirmEmail" v-slashes>{{ __('common.giveitatry') }}
              </div>
            </div>
            <div class="flex xs6">
              <div class="btn btn--primary" @click="close" v-slashes>{{ __('common.close') }}</div>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import apiDefault from '../../api/default';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';

  export default {
    mixins: [notificationsMixin, localizationMixin],
    data: () => ({
      loading: false,
      user: {
        username: '',
        confirmationToken: ''
      },
      showMe: false,
      changedProperties: [],
      errors: {
        username: [],
        confirmationToken: []
      }
    }),
    methods: {
      show() {
        this.showMe = true;
      },
      close() {
        this.showMe = false;
      },
      confirmEmail() {
        let isValid = true;
        this.errors = {
          username: [],
          confirmationToken: []
        };

        if (this.user.username.length < process.env.VUE_APP_CONSTRAINT_USERNAME_MINLENGTH) {
          this.errors.username.push(this.__('component.auth.confirm_email.username_invalid').replace('$1', process.env.VUE_APP_CONSTRAINT_USERNAME_MINLENGTH));
          isValid = false;
        }
        if (this.user.confirmationToken.length < 200 || this.user.confirmationToken.length > 300) {
          this.errors.confirmationToken.push(this.__('common.token_invalid'));
          isValid = false;
        }

        if (!isValid) {
          return;
        }

        this.loading = true;
        apiDefault.patch('/authentication/Unauthenticated/confirm-email',{
          username: this.user.username,
          token: this.user.confirmationToken
        }).then((res) => {
          this.loading = false;
          this.showSuccessNotification(res.data.message);
          this.showMe = false;
          this.$emit('emailConfirmationSuccessful', this.user);
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      }
    }
  }
</script>