<template>
  <div class="text-field" :class="textFieldClasses">
    <input
        :id="inputId"
        :type="type"
        :maxlength="maxlength"
        :min="min"
        :max="max"
        :readonly="readonly"
        v-bind:value="value"
        v-on:input="$emit('input', $event.target.value)"
        v-on:blur="$emit('blur', $event.target.value)"
        @focus="handleFocus"
        @blur="focused = false"
        @keyup.enter="emitKeyupEnter"
    >
    <label :for="inputId">{{label}}</label>
    <div class="text-field__prepend-icon" v-if="prependIcon">
      <icon :icon="prependIcon"/>
    </div>
    <div class="text-field__append-icon" v-if="processedAppendIcon" @click="emitClickAppend">
      <icon :icon="processedAppendIcon"/>
    </div>
    <div class="text-field__info">
      <div class="text-field__hint">{{hint}}</div>
      <div class="text-field__error-messages">{{joinedErrorMessages}}</div>
      <div class="text-field__counter" v-if="type !== 'number'">{{ value ? value.length : 0}}/{{maxlength}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TextField",
    data: () => ({
      inputId: null,
      focused: false
    }),
    props: {
      value: {
        default: ''
      },
      type: {
        type: String,
        default: 'text'
      },
      label: {
        type: String,
        default: ''
      },
      maxlength: {
        default: 255
      },
      hint: {
        type: String,
        default: null
      },
      errorMessages: {
        type: Array,
        default: function () {
          return [];
        }
      },
      prependIcon: {
        type: String,
        default: null
      },
      appendIcon: {
        type: String,
        default: null
      },
      hideDetails: {
        type: Boolean,
        default: false
      },
      min: {
        default: null
      },
      max: {
        default: null
      },
      clearable: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      processedAppendIcon() {
        if(this.clearable === true) {
          if(this.value && this.value.length > 0) {
            return 'cross';
          }else {
            return null;
          }
        }
        return this.appendIcon;
      },
      textFieldClasses() {
        return {
          'text-field--focused': this.focused,
          'text-field--error': this.errorMessages.length > 0,
          'text-field--prepend-icon': this.prependIcon,
          'text-field--append-icon': this.appendIcon,
          'text-field--hide-details': this.hideDetails
        }
      },
      joinedErrorMessages() {
        return this.errorMessages.join('<br/>');
      }
    },
    methods: {
      handleFocus(event) {
        this.focused = true;
        event.target.select();
      },
      emitClickAppend(event) {
        if(this.clearable === true) {
          this.$emit('input', '');
          this.$emit('click:clear', event);
        } else {
          this.$emit('click:append', event);
        }
      },
      emitKeyupEnter(event) {
        event.preventDefault();
        this.$emit('keyup:enter', event);
      }
    },
    created() {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < 32; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      this.inputId = result;
    }
  }
</script>