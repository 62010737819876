import en from '../localizations/en';
import de from '../localizations/de';

const localizations = { en, de };
const defaultLanguage = 'en';

export const localizationMixin = {
  methods: {
    __(key) {
      let language = localStorage.getItem('preferredLanguage');
      if (!language) {
        language = navigator.language.split('-')[0];
      }

      if(!localizations.hasOwnProperty(language)) {
        language = defaultLanguage;
      }

      if (localizations[language].hasOwnProperty(key)) {
        return localizations[language][key];
      } else if (localizations[defaultLanguage].hasOwnProperty(key)) {
        return localizations[defaultLanguage][key];
      }
      return key;
    }
  }
};