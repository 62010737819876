<template>
  <div class="single-select">
    <div v-for="item in items"
         class="single-select__item"
         :class="{'single-select__item--selected': value === item.value}"
         @click="emitInput(item.value)"
         v-slashes
    >
      <div class="single-select__item-icon" v-if="item.icon"></div>
      <div class="single-select__item-title">{{item.title}}</div>
    </div>
    <div class="single-select__append-icon" v-if="appendIcon"><icon :icon="appendIcon"/></div>
  </div>
</template>

<script>
  export default {
    name: "SingleSelect",
    props: {
      value: {
        default: null
      },
      items: {
        type: Array,
        default: () => []
      },
      appendIcon: {
        type: String,
        default: null
      }
    },
    data: () => ({}),
    methods: {
      emitInput(value) {
        if(this.value !== value) {
          this.$emit('input', value);
        }
      }
    }
  }
</script>