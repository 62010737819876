<template>
  <div class="main-menu-wrapper" :class="{'main-menu-wrapper--open': $store.state.mainMenuOpen}" @click="$store.commit('closeMainMenu')">
    <div class="main-menu">
      <router-link class="main-menu__item" :to="{name: 'auth_users'}" v-if="hasOneRoles([])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/user-mc.svg)'}"></div>
        <div class="main-menu__item-title">{{__('entity.common.users')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'auth_roles'}" v-if="hasOneRoles([])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/user-group-mc.svg)'}"></div>
        <div class="main-menu__item-title">{{__('entity.common.roles')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'auth_statistics'}" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/pie-chart-mc.svg)'}"></div>
        <div class="main-menu__item-title">User Stats</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'auth_dll-logs'}" v-if="hasOneRoles([])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/chip-mc.svg)'}"></div>
        <div class="main-menu__item-title">DLL Logs</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'auth_dll-versions'}" v-if="hasOneRoles([])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/chip-mc.svg)'}"></div>
        <div class="main-menu__item-title">DLL Versions</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'auth_hardware-ids'}" v-if="hasOneRoles([])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/chip-mc.svg)'}"></div>
        <div class="main-menu__item-title">DLL Hardware Ids</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'ingame_groups'}" v-if="hasOneRoles(['InGame Items Editor'])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/elements.png)'}"></div>
        <div class="main-menu__item-title">{{__('entity.common.groups')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'ingame_items'}" v-if="hasOneRoles(['InGame Items Editor'])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ingame/iconsLib/elementslib/coreunitdynamic128.png)'}"></div>
        <div class="main-menu__item-title">{{__('entity.common.items')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'ingame_item_reports'}" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/item-reports-mc.svg)'}"></div>
        <div class="main-menu__item-title">{{__('itemReports')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'ingame_imported_items'}" v-if="hasOneRoles([])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ingame/iconsLib/elementslib/coreunitdynamic128.png)'}"></div>
        <div class="main-menu__item-title">Imported Items</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'ingame_stellar_objects'}" v-if="hasOneRoles(['InGame Items Editor'])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/stellar-objects-mc.svg)'}"></div>
        <div class="main-menu__item-title">{{__('entity.common.stellar_objects_split')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'ingame_scan_results'}" v-if="hasOneRoles(['Member'])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ingame/iconsLib/elementslib/territoryscanner.png)'}"></div>
        <div class="main-menu__item-title">{{__('entity.common.scan_results_split')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'ingame_organizations'}" v-if="hasOneRoles(['Member', 'Allied Legate'])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/organizations-mc.svg)'}"></div>
        <div class="main-menu__item-title">{{__('entity.common.organizations_split')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'ingame_players'}" v-if="hasOneRoles(['Member', 'Allied Legate'])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/player-mc.svg)'}"></div>
        <div class="main-menu__item-title">{{__('entity.common.players')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'ingame_player_registration_logs'}" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/player-registration-logs-mc.svg)'}"></div>
        <div class="main-menu__item-title">{{__('playerRegistrationLogs')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'ingame_constructs'}" v-if="hasOneRoles(['Member'])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/constructs-mc.svg)'}"></div>
        <div class="main-menu__item-title">{{__('entity.common.constructs')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'ingame_organization_membership_logs'}" v-if="hasOneRoles(['Member', 'Allied Legate'])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/change-log-mc.svg)'}"></div>
        <div class="main-menu__item-title">{{__('organizationMembershipLogs')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'ingame_projects'}" v-if="hasOneRoles(['Member'])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/projects.svg)'}"></div>
        <div class="main-menu__item-title">{{__('entity.common.projects')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'ingame_ships'}" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/expo-ship-mc.svg)'}"></div>
        <div class="main-menu__item-title">AAE Ship Registration</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'ingame_ship_classes'}" v-if="hasOneRoles(['Administrator'])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/expo-ship-mc.svg)'}"></div>
        <div class="main-menu__item-title">{{__('shipClasses')}}</div>
      </router-link>
<!--      <router-link class="main-menu__item" :to="{name: 'ingame_storages'}" v-if="hasOneRoles(['Member'])" v-slashes>-->
<!--        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ingame/iconsLib/elementslib/containerxl.png)'}"></div>-->
<!--        <div class="main-menu__item-title">{{__('entity.common.storages')}}</div>-->
<!--      </router-link>-->
      <router-link class="main-menu__item" :to="{name: 'file_browser'}" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/cloud-mc.svg)'}"></div>
        <div class="main-menu__item-title">{{__('common.file_browser')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'playerprogression_skilltree'}" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/skill-tree-mc.svg)'}"></div>
        <div class="main-menu__item-title">{{__('common.skill_tree_split')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'crafting_calculator'}" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/crafting-calculator-mc.svg)'}"></div>
        <div class="main-menu__item-title">{{__('common.crafting_calculator')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'ingame_lua_export'}" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/Lua-Logo.svg)'}"></div>
        <div class="main-menu__item-title">Lua Export</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'crafting_calculator_logs'}" v-if="hasOneRoles(['Bereichsleiter'])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/cc-logs-mc.svg)'}"></div>
        <div class="main-menu__item-title">Crafting Logs</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'ore_appearance_reports'}" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/ore-report-mc.svg)'}"></div>
        <div class="main-menu__item-title">{{__('oreAppearanceReportsSplit')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'ore_appearances'}" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/ores-mc.svg)'}"></div>
        <div class="main-menu__item-title">{{__('entity.common.ore_appearances_split')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'voxel_library'}" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/voxe-mc.svg)'}"></div>
        <div class="main-menu__item-title">Voxel {{__('library')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'item_variation_library'}" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/iconslib/atmosphericenginemedium.png)'}"></div>
        <div class="main-menu__item-title">{{__('itemVariationsLibrary')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'member'}" v-if="hasOneRoles(['Member'])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/real-helmets_01.svg)'}"></div>
        <div class="main-menu__item-title">{{__('common.members')}}</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'hyperion'}" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/logo-mc.svg)'}"></div>
        <div class="main-menu__item-title">Hyperion</div>
      </router-link>
      <router-link class="main-menu__item" :to="{name: 'todos'}" v-if="hasOneRoles(['Member'])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/todos-mc.svg)'}"></div>
        <div class="main-menu__item-title">Todos</div>
      </router-link>
<!--      <router-link class="main-menu__item" :to="{name: 'ingame_statistics'}" v-if="hasOneRoles(['Member'])" v-slashes>-->
<!--        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/pie-chart-mc.svg)'}"></div>-->
<!--        <div class="main-menu__item-title">{{__('common.statistics')}}</div>-->
<!--      </router-link>-->
      <router-link class="main-menu__item" :to="{name: 'git'}" v-if="hasOneRoles(['Member'])" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/gitlab-icon-1-color-white-rgb.svg)'}"></div>
        <div class="main-menu__item-title">Gitlab</div>
      </router-link>
      <a class="main-menu__item" href="https://forum.hyperion-corporation.de" v-slashes>
        <div class="main-menu__item-icon" :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/svgs/forum.svg)'}"></div>
        <div class="main-menu__item-title">Forum</div>
      </a>
    </div>
  </div>
</template>

<script>
  import { userMixin } from "../mixins/user";
  import { localizationMixin } from '../mixins/localization';

  export default {
    mixins: [userMixin, localizationMixin],
    props: ['showMenu'],
    data: () => ({

    }),
    methods: {
      close() {
        this.$store.commit('')
      }
    }
  }
</script>
