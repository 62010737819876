<template>
  <div class="switch" :class="componentClasses" @click="handleInput">
    <div class="switch__area">
      <div class="switch__button"></div>
    </div>
    <div class="switch__label">{{label}}</div>
    <div class="switch__append-icon" v-if="appendIcon"><icon :icon="appendIcon"></icon></div>
  </div>
</template>

<script>
  export default {
    name: "MySwitch",
    props: {
      value: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ''
      },
      appendIcon: {
        type: String,
        default: null
      }
    },
    computed: {
      componentClasses() {
        return {
          'switch--disabled': this.disabled,
          'switch--on': this.value
        }
      }
    },
    methods: {
      handleInput() {
        this.$emit('input', !this.value);
      }
    }
  }
</script>