<template>
  <div class="multi-select">
    <div class="multi-select__selected">
      <div class="multi-select__selected-item" v-for="selectedItem in value">
        <div class="multi-select__selected-item-title">{{selectedItem}}</div>
        <div class="multi-select__selected-item-remove" @click="emitDeselection(selectedItem)">
          <icon :icon="'bin'"/>
        </div>
      </div>
      <div class="multi-select__action" :title="__('common.add_an_item')" @click="openDialog()">
        <icon :icon="'add'"/>
      </div>
      <div class="multi-select__prepend-icon" :title="__('common.unsaved_changes')" v-if="prependIcon">
        <icon :icon="prependIcon"/>
      </div>
    </div>
    <my-dialog :show="showDialog" :max-width="'400px'">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('common.select_one_or_more')}}</div>
          <div class="card__button" @click="closeDialog" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="multi-select__selected">
          <div class="multi-select__selected-item" v-for="selectedItem in value">
            <div class="multi-select__selected-item-title">{{selectedItem}}</div>
            <div class="multi-select__selected-item-remove"
                 :title="__('common.remove_this_item')"
                 @click="emitDeselection(selectedItem)"
            >
              <icon :icon="'bin'"/>
            </div>
          </div>
          <div class="multi-select__prepend-icon" :title="__('common.unsaved_changes')" v-if="prependIcon">
            <icon :icon="prependIcon"/>
          </div>
        </div>
        <div class="card__content">
          <div class="selection-list" v-if="filteredItems.length > 0">
            <div class="selection-list__item" v-for="item in filteredItems" v-slashes @click="emitSelection(item)">
              <div class="selection-list__item-title">
                {{ item }}
              </div>
            </div>
          </div>
          <p class="mt" v-else>{{__('component.ingame.common.item_browser.select_item_first')}}</p>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import {notificationsMixin} from '../../mixins/notifications';
  import {localizationMixin} from '../../mixins/localization';

  export default {
    name: "MultiSelectFlat",
    mixins: [notificationsMixin, localizationMixin],
    props: {
      value: {
        type: Array,
        default: () => []
      },
      prependIcon: {
        type: String,
        default: null
      },
      availableItems: {
        type: Array,
        default: () => []
      },
    },
    data: () => ({
      showDialog: false
    }),
    computed: {
      filteredItems() {
        return this.availableItems.filter(availableItem => !this.value.includes(availableItem));
      }
    },
    methods: {
      openDialog() {
        this.showDialog = true;
      },
      closeDialog() {
        this.showDialog = false;
      },
      emitSelection(item) {
        this.$emit('itemSelected', item);
        this.$emit('input', [...this.value, item]);
        this.$forceUpdate();
      },
      emitDeselection(item) {
        this.$emit('itemDeselected', item);
        const itemIndex = this.value.findIndex(selectedItem => selectedItem === item);
        const newValue = this.value.filter((item, index) => itemIndex !== index);
        this.$emit('input', newValue);
        this.$forceUpdate();
      },
    }
  }
</script>