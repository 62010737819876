<template>
  <div class="login">
    <div class="login__content">
      <img src="https://cdn.hyperion-corporation.de/ui/svgs/hyperium-logo_1.svg" alt="Hyperion"/>
      <h1 class="login__title">Headquarters</h1>
      <text-field
          class="mt"
          v-model="username"
          :label="__('entity.auth.user.username')"
          maxlength="25"
          :hint="__('common.not_email_username')"
          @keyup.enter="login"
      />
      <text-field
          v-model="password"
          :label="__('entity.auth.user.password')"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'eye' : 'eye-off'"
          @click:append="showPassword = !showPassword"
          @keyup:enter="login"
      />
      <div class="flex xs12 mt">
        <div class="tile">
          <p class="text--lime">{{__('browserHint')}}</p>
          <p>{{__('cookieDisclaimer')}}</p>
          <my-switch
              v-model="cookiesAllowed"
              :label="__('cookieQuestion')"
          ></my-switch>
        </div>
      </div>
      <div class="text--center">
        <div class="btn" @click="login"
             :disabled="username.length < getEnvV('CONSTRAINT_USERNAME_MINLENGTH') || password.length < getEnvV('CONSTRAINT_PASSWORD_MINLENGTH') || !cookiesAllowed"
             :loading="loading" v-slashes>{{__('component.auth.login.login')}}
        </div>
        <div class="btn mt" @click="loginWithDiscord"
             :disabled="!cookiesAllowed"
             :loading="loading" v-slashes>
          <img class="btn__icon" src="https://cdn.hyperion-corporation.de/ui/svgs/Discord-Logo-Wite.svg" alt="Login with discord" title="Login with Discord"/>
          {{__('component.auth.login.login')}} with Discord
        </div>
        <div class="btn btn--accent mt" @click="showRegistration" :loading="loading" v-slashes>
          {{__('component.auth.login.register')}}
        </div>
        <div class="btn btn--accent mt" @click="showConfirmEmail" :loading="loading" v-slashes>
          {{__('component.auth.login.confirm_email')}}
        </div>
        <div class="btn btn--accent mt" @click="showRequestPasswordReset" :loading="loading" v-slashes>
          {{__('component.auth.login.request_password_reset')}}
        </div>
        <div class="btn btn--accent mt" @click="showResetPassword" :loading="loading" v-slashes>
          {{__('component.auth.login.reset_password')}}
        </div>
      </div>
    </div>
    <register ref="register" @registrationSuccessful="showConfirmEmail"/>
    <confirm-email ref="confirmEmail" @emailConfirmationSuccessful="showActiveHint"/>
    <request-password-reset ref="requestPasswordReset" @resetPasswordRequested="showResetPassword"/>
    <reset-password ref="resetPassword"/>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import apiDefault from '../../api/default';
  import { saveTokens } from '../../api/secured';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { envMixin } from "../../mixins/env";
  import Register from '../../components/Auth/Register';
  import ConfirmEmail from '../../components/Auth/ConfirmEmail';
  import RequestPasswordReset from "./RequestPasswordReset";
  import ResetPassword from "./ResetPassword";

  export default {
    mixins: [notificationsMixin, localizationMixin, envMixin],
    components: {
      ResetPassword,
      RequestPasswordReset,
      register: Register,
      confirmEmail: ConfirmEmail
    },
    data: () => ({
      username: '',
      password: '',
      showPassword: false,
      loading: false,
      cookiesAllowed: false
    }),
    watch: {
      user() {
        if(this.user) {
          this.$router.push('/');
        }
      }
    },
    computed: {
      user() {
        return this.$store.state.user;
      }
    },
    methods: {
      loginWithDiscord() {
        if(!this.loading) {
          this.$router.push({ name: 'discord-handle' });
        }
      },
      login() {
        if(this.username.length < this.getEnvV('CONSTRAINT_USERNAME_MINLENGTH') || this.password.length < this.getEnvV('CONSTRAINT_PASSWORD_MINLENGTH') || !this.cookiesAllowed || this.loading) {
          return;
        }
        this.loading = true;
        apiDefault.post('/authentication/token/sign-in', {
          username: this.username,
          password: this.password
        }).then((res) => {
          saveTokens(res.data);
          this.$store.commit('setUser');
          this.loading = false;
          this.showSuccessNotification(this.__('component.auth.login.welcome') + ' ' + this.$store.state.user.username);
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      showRegistration() {
        this.$refs.register.show();
      },
      showConfirmEmail() {
        this.$refs.confirmEmail.show();
      },
      showActiveHint(user) {
        Swal.fire({
          type: 'success',
          title: this.__('component.auth.login.welcome') + ' ' + user.username,
          text: this.__('component.auth.login.account_activated')
        })
      },
      showRequestPasswordReset() {
        this.$refs.requestPasswordReset.show();
      },
      showResetPassword() {
        this.$refs.resetPassword.show();
      }
    }
  }
</script>

<style>

</style>
