<template>
  <div class="register">
    <my-dialog :show="showMe" @close="close" max-width="400px">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('component.auth.register.title')}}</div>
        </div>
        <progress-linear v-if="loading" :indeterminate="true"/>
        <div class="card__content">
          <div class="layout layout--wrap">
            <div class="flex xs12">
              <p>{{__('common.privacy_policy_hint')}}</p>
              <p class="text--primary">{{__('credentialsHint')}}</p>
              <router-link class="btn btn--accent" :to="{name: 'privacy_policy'}" target="_blank" v-slashes>{{__('common.privacyPolicy')}}</router-link>
            </div>
            <div class="flex xs12 mt">
              <my-switch
                  v-model="privacyPolicyAgreed"
                  :label="__('common.privacy_policy_agreed')"
              ></my-switch>
            </div>
            <div class="flex xs12 mt">
              <text-field
                  v-model="user.username"
                  :label="__('entity.auth.user.username')"
                  :error-messages="errors.username"
                  :hint="__('validation.too_short').replace('$1', 'username').replace('$2', getEnvV('CONSTRAINT_USERNAME_MINLENGTH'))"
                  maxlength="50"
              />
            </div>
            <div class="flex xs12">
              <text-field
                  v-model="user.email"
                  :label="__('entity.auth.user.email')"
                  :error-messages="errors.email"
                  :hint="__('component.auth.edit_user.email_not_valid')"
                  type="email"
                  maxlength="50"
              />
            </div>
            <div class="flex xs12">
              <text-field
                  v-model="user.password"
                  :label="__('component.auth.edit_myself.new_password')"
                  :type="showPassword ? 'text' : 'password'"
                  :error-messages="errors.password"
                  :hint="__('validation.too_short').replace('$1', 'password').replace('$2', getEnvV('CONSTRAINT_PASSWORD_MINLENGTH'))"
                  :append-icon="showPassword ? 'eye' : 'eye-off'"
                  @click:append="showPassword = !showPassword"
              />
            </div>
            <div class="flex xs12">
              <text-field
                  v-model="user.passwordRepeat"
                  :label="__('component.auth.edit_myself.new_password_repeat')"
                  :type="showPassword ? 'text' : 'password'"
                  :error-messages="errors.passwordRepeat"
                  :hint="__('component.auth.edit_user.password_not_repeated')"
                  :append-icon="showPassword ? 'eye' : 'eye-off'"
                  @click:append="showPassword = !showPassword"
              />
            </div>
            <div class="flex xs6">
              <div class="btn btn--success" :disabled="isInValid || !privacyPolicyAgreed" :loading="loading" @click="registerUser" v-slashes>
                {{ __('common.giveitatry') }}
              </div>
            </div>
            <div class="flex xs6">
              <div class="btn btn--primary" @click="close" v-slashes>{{ __('common.close') }}</div>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import isEmail from 'validator/lib/isEmail';
  import apiDefault from '../../api/default';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { envMixin } from "../../mixins/env";

  export default {
    mixins: [notificationsMixin, localizationMixin, envMixin],
    data: () => ({
      loading: false,
      privacyPolicyAgreed: false,
      user: {
        username: '',
        email: '',
        password: '',
        passwordRepeat: ''
      },
      showMe: false,
      changedProperties: [],
      showPassword: false,
      errors: {
        username: [],
        email: [],
        password: [],
        passwordRepeat: []
      }
    }),
    computed: {
      isInValid() {
        const usernameTooShort = this.user.username.length < process.env.VUE_APP_CONSTRAINT_USERNAME_MINLENGTH;
        const emailNotValid = this.user.email.length < process.env.VUE_APP_CONSTRAINT_USERNAME_MINLENGTH || !isEmail(this.user.email);
        const passwordTooShort = this.user.password.length < process.env.VUE_APP_CONSTRAINT_USERNAME_MINLENGTH;
        const passwordNotRepeated = this.user.password !== this.user.passwordRepeat;
        return usernameTooShort || emailNotValid || passwordTooShort || passwordNotRepeated;
      }
    },
    methods: {
      show() {
        this.showMe = true;
      },
      registerChange(property) {
        if (!this.changedProperties.includes(property)) {
          this.changedProperties.push(property);
        }
      },
      isChanged(property) {
        return this.changedProperties.includes(property);
      },
      close() {
        this.showMe = false;
      },
      registerUser() {
        let isValid = true;
        this.errors = {
          username: [],
          email: [],
          password: [],
          passwordRepeat: []
        };
        if (this.user.username.length < process.env.VUE_APP_CONSTRAINT_USERNAME_MINLENGTH) {
          this.errors.username.push(this.__('validation.too_short').replace('$1', 'username').replace('$2', process.env.VUE_APP_CONSTRAINT_USERNAME_MINLENGTH));
          isValid = false;
        }

        if (this.user.password.length < process.env.VUE_APP_CONSTRAINT_USERNAME_MINLENGTH) {
          this.errors.password.push(this.__('validation.too_short').replace('$1', 'password').replace('$2', process.env.VUE_APP_CONSTRAINT_PASSWORD_MINLENGTH));
          isValid = false;
        } else if (this.user.password !== this.user.passwordRepeat) {
          this.errors.passwordRepeat.push(this.__('component.auth.edit_user.password_not_repeated'));
          isValid = false;
        }

        if (this.user.email.length < process.env.VUE_APP_CONSTRAINT_USERNAME_MINLENGTH || !isEmail(this.user.email)) {
          this.errors.email.push(this.__('component.auth.edit_user.email_not_valid'));
          isValid = false;
        }

        if (!isValid) {
          return;
        }

        this.loading = true;
        apiDefault.post('/authentication/Unauthenticated/sign-up', {
          username: this.user.username,
          password: this.user.password,
          email: this.user.email
        }).then((res) => {
          this.loading = false;
          this.showSuccessNotification(res.data.message);
          this.changedProperties = [];
          this.user.password = '';
          this.user.passwordRepeat = '';
          this.showMe = false;
          this.$emit('registrationSuccessful', this.user);
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      }
    }
  }
</script>