import Vue from 'vue';
import Vuex from 'vuex';
import Cookie from 'js-cookie';
import notificationsStore from './store/notificationsStore';

Vue.use(Vuex);

function removeCookie() {
  Cookie.remove(process.env.VUE_APP_NODEBB_COOKIE_NAME, {
    domain: process.env.VUE_APP_NODEBB_COOKIE_DOMAIN,
    secure: process.env.VUE_APP_NODEBB_COOKIE_SECURE
  });
}

export default new Vuex.Store({
  modules: {notificationsStore},
  state: {
    user: null,
    mainMenuOpen: false
  },
  getters: {
    hasOneRoles: (state) => (targetRoles) => {
      let user = state.user;
      let hasOneRole = false;

      const combinedTargetRoles = ['Administrator', ...targetRoles];
      if (!user) {
        return false;
      }

      let userRoles = [];
      if (typeof user.roles === 'object') {
        userRoles = Object.keys(user.roles).map((k) => user.roles[k]);
      } else {
        userRoles = user.roles;
      }

      combinedTargetRoles.forEach((targetRole) => {
        console.log(targetRole);
        userRoles.forEach((role) => {
          if (role && targetRole && role.toUpperCase() === targetRole.toUpperCase()) {
            hasOneRole = true;
          }
        });
      });
      return hasOneRole;
    },
    hasAllRoles: (state) => (targetRoles) => {
      let user = state.user;
      if (!user) {
        return false;
      }

      let userRoles = [];
      if (typeof user.roles === 'object') {
        userRoles = Object.keys(user.roles).map((k) => user.roles[k]);
      } else {
        userRoles = user.roles;
      }

      if (userRoles.includes('Administrator')) {
        return true;
      }
      let matchedRoles = 0;
      targetRoles.forEach((targetRole) => {
        userRoles.forEach((role) => {
          if (role.toUpperCase() === targetRole.toUpperCase()) {
            matchedRoles++;
          }
        });
      });
      return matchedRoles === targetRoles.length;
    }
  },
  mutations: {
    openMainMenu(state) {
      state.mainMenuOpen = true;
    },
    closeMainMenu(state) {
      state.mainMenuOpen = false;
    },
    setUser(state) {
      let accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        state.user = JSON.parse(window.atob(accessToken.split('.')[1]));
        if(window._paq && window._paq.some) {
          if (!window._paq.some(entry => entry[0] === 'setUserId')) {
            window._paq.push(['setUserId', state.user.id])
          }
        }
      } else {
        state.user = null;
      }
    },
    deleteUser(state, deleteOnly = false) {
      if (state.user) {
        if (deleteOnly) {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('gitlabToken');
          state.user = null;
          removeCookie();
        } else {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('gitlabToken');
          state.user = null;
          removeCookie();
        }
      } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('gitlabToken');
        removeCookie();
      }
      _paq.push(['resetUserId']);
    }
  },
  actions: {}
})
