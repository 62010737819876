export const paginationMixin = {
  data: () => ({
    skipCreated: false,
    sortBy: 'createdAt',
    sortDesc: true,
    searchTerm: '',
    pagination: {
      page: 1,
      pages: 1,
      recordsPerPage: 20,
      total: 0
    },
  }),
  watch: {
    searchTerm() {
      this.pagination.page = 1;
    }
  },
  methods: {
    getPaginationParams() {
      return {
        page: this.pagination.page,
        pageSize: this.pagination.recordsPerPage,
        searchQuery: this.searchTerm,
        orderBy: `${this.sortBy} ${this.sortDesc ? 'desc' : 'asc'}`,
        orderDesc: this.sortDesc ? 1 : 0
      }
    },
    updatePagination(response) {
      this.pagination.page = parseInt(response.headers["pagination.page"]);
      this.pagination.pages = parseInt(response.headers["pagination.totalpages"]);
      this.pagination.pageSize = parseInt(response.headers["pagination.pagesize"]);
      this.pagination.total = parseInt(response.headers["pagination.totalcount"]);
    },
    paginate(page) {
      this.pagination.page = page;
      this.refresh();
    },
    sort({sortBy, sortDesc}) {
      this.sortBy = sortBy;
      this.sortDesc = sortDesc;
      this.refresh();
    },
  },
  created() {
    if(!this.skipCreated) {
      for (let key in this.$route.query) {
        switch (key) {
          case 'page':
          case 'limit':
            this.pagination[key] = parseInt(this.$route.query[key]);
            break;

          case 'orderDesc':
            this.orderDesc = parseInt(this.$route.query.orderDesc);
            break;

          case 'q':
            this.searchTerm = this.$route.query.q;
            break;

          case 'orderBy':
            this.orderBy = this.$route.query.orderBy
        }
      }
    }
  }
};