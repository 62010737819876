<template>
  <div class="date-time-wrapper" :class="fieldClasses">
    <div class="date-time-wrapper__prepend-icon" v-if="prependIcon"><icon :icon="prependIcon"/></div>
    <datetime v-model="fakedValue" @input="handleInput" type="datetime" input-style="object"></datetime>
    <div class="date-time-wrapper__append-icon" v-if="appendIcon"><icon :icon="appendIcon"/></div>
    <div class="date-time-wrapper__info">
      <div class="date-time-wrapper__error-messages" v-if="errorMessages.length">{{joinedErrorMessages}}</div>
    </div>
  </div>
</template>

<script>
  import { Datetime } from 'vue-datetime';
  import 'vue-datetime/dist/vue-datetime.css'

  export default {
    name: "DateTimeWrapper",
    components: { Datetime },
    props: {
      value: {
        type: String,
        default: null
      },
      prependIcon: {
        type: String,
        default: null
      },
      appendIcon: {
        type: String,
        default: null
      },
      hideInfo: {
        type: Boolean,
        default: false
      },
      errorMessages: {
        type: Array,
        default: function () {
          return [];
        }
      },
    },
    watch: {
      value() {
        this.fakedValue = this.value;
      }
    },
    data: () => ({
      fakedValue: null
    }),
    computed: {
      fieldClasses() {
        return {
          'date-time-wrapper--prepend-icon': this.prependIcon,
          'date-time-wrapper--append-icon': this.appendIcon,
          'date-time-wrapper--error': this.errorMessages.length > 0,
          'date-time-wrapper--hide-info': this.hideInfo
        }
      },
      joinedErrorMessages() {
        return this.errorMessages.join('<br/>');
      }
    },
    methods: {
      handleInput(value) {
        this.$emit('input', value);
      }
    }
  }
</script>