export const userMixin = {
  methods: {
    hasOneRoles(roles) {
      return this.$store.getters.hasOneRoles(roles)
    },
    hasAllRoles(roles) {
      return this.$store.getters.hasAllRoles(roles)
    },
    hasModAccess(record) {
      if(record === null) {
        return this.$store.getters.hasOneRoles([]);
      }
      if(!record.hasOwnProperty('ownerId')) {
        return false;
      }
      if (this.$store.state.user.id === record.ownerId) {
        return true;
      }
      return this.$store.getters.hasOneRoles(record.modAccesses.map((modAccess) => {
        if (typeof modAccess === 'string') {
          return modAccess;
        }
        return modAccess.role.name;
      }));
    }
  }
};