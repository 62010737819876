<template>
  <transition-group name="bounceRight" tag="div" class="notifications-wrapper">
    <div class="notification"
        v-for="notification in $store.getters.notifications"
        :key="notification.key"
        :class="getNotificationClass(notification)"
    >
      <div class="notification__icon"><icon :icon="notification.icon"/></div>
      <div class="notification__message">{{ notification.message }}</div>
      <div class="notification__dismiss" @click="$store.dispatch('removeNotification',notification)" v-slashes><icon :icon="'cross'"/></div>
    </div>
  </transition-group>
</template>

<script>
  export default {
    methods: {
      getNotificationClass(notification) {
        return {
          ['notification--' + notification.color]: true
        };
      }
    }
  }
</script>