<template>
  <div class="select-reference" :class="fieldClasses">
    <progress-linear v-if="loading"/>
    <div class="select-reference__append-icon" v-if="prependIcon">
      <icon :icon="prependIcon"/>
    </div>
    <div class="select-reference__title" @click="openDialog">
      <template v-if="value">{{ selectedItem ? selectedItem[fieldTitle] : value }}</template>
      <template v-else>{{this.nothingSelectedMessage}}</template>
    </div>
    <div class="select-reference__title select-reference__title--remove" v-if="value && !readonly" @click="emitSelection(null)">
      <icon :icon="'bin'"/>
    </div>
    <div class="select-reference__info">
      <div class="select-reference__error-messages" v-if="errorMessages.length">{{joinedErrorMessages}}</div>
    </div>
    <my-dialog :show="showDialog" :max-width="'400px'">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('common.select_one_or_more')}}</div>
          <div class="card__button" @click="closeDialog" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <progress-linear v-if="loading"/>
        <div class="card__content">
          <div class="control-bar control-bar--no-h-space" :class="{'control-bar--disabled': loading}">
            <text-field
                class="control-bar__search"
                v-model="searchTerm"
                :label="__('common.search_term')"
                max-length="50"
                :hide-details="true"
                :clearable="true"
                @keyup:enter="refreshDelayed(100)"
                @click:clear="refreshDelayed(100)"
            />
            <div class="control-bar__buttons">
              <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
                <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
                     alt="refresh"/>
              </div>
              <div class="control-bar__button" :title="__('common.add')" @click="emitCreate" v-slashes
                   v-if="showCreate">
                <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/add.svg"
                     alt="add"/>
              </div>
            </div>
          </div>
          <pagination
              class="mt"
              :count-pages="pagination.pages"
              :total-visible="5"
              :page="pagination.page"
              :disabled="loading"
              @paginate="paginate"
          />
          <div class="selection-list mt">
            <div class="selection-list__item" v-for="item in filteredItems" v-slashes @click="emitSelection(item)" :style="getItemStyle(item)">
              <div class="selection-list__item-icon" v-if="item[fieldIcon] && item[fieldIcon].substr(0,5).toLowerCase() === 'https'">
                <img :src="item[fieldIcon]" alt="-"/>
              </div>
              <div class="selection-list__item-icon" v-else-if="item[fieldIcon] && item[fieldIcon].substr(0,'assets.prod.novaquark.com'.length).toLowerCase() === 'assets.prod.novaquark.com'">
                <img :src="`https://${item[fieldIcon]}`" alt="-"/>
              </div>
              <div v-else class="selection-list__item-icon" v-html="item[fieldIcon]"></div>
              <div class="selection-list__item-title">
                {{ item[fieldTitle] }}
                <div class="selection-list__item-description bodytext" v-if="fieldDescription" v-html="item[fieldDescription]"></div>
              </div>
              <div class="selection-list__item-action" v-if="showEdit" @click="emitEdit($event, item.id)">
                <icon :icon="'pencil'"/>
              </div>
            </div>
          </div>
          <pagination
              class="mt"
              :count-pages="pagination.pages"
              :total-visible="5"
              :page="pagination.page"
              :disabled="loading"
              @paginate="paginate"
          />
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import apiSecured from '../../api/secured'
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { paginationMixin } from "../../mixins/pagination";

  export default {
    name: "SelectReference",
    mixins: [notificationsMixin, localizationMixin, paginationMixin],
    props: {
      value: {
        type: String | Number,
        default: () => null
      },
      prependIcon: {
        type: String,
        default: null
      },
      endpoint: {
        type: String,
        default: null
      },
      endpointParams: {
        type: Object,
        default: () => {}
      },
      fieldValue: {
        type: String,
        default: 'id'
      },
      fieldTitle: {
        type: String,
        default: 'name'
      },
      fieldIcon: {
        type: String,
        default: 'icon'
      },
      fieldDescription: {
        type: String,
        default: null
      },
      fieldColor: {
        type: String,
        default: null
      },
      nothingSelectedMessage: {
        type: String,
        default: 'No reference selected'
      },
      showCreate: {
        type: Boolean,
        default: false
      },
      showEdit: {
        type: Boolean,
        default: false
      },
      hideInfo: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false
      },
      errorMessages: {
        type: Array,
        default: function () {
          return [];
        }
      },
      forceAvailableItems: {
        type: Array,
        default: function () {
          return [];
        }
      }
    },
    data: () => ({
      skipCreated: true,
      loading: false,
      availableItems: [],
      selectedItem: {},
      searchTerm: '',
      showDialog: false
    }),
    computed: {
      filteredItems() {
        return this.availableItems.filter(availableItem => {
          return this.value !== availableItem.id;
        })
      },
      fieldClasses() {
        return {
          'select-reference--error': this.errorMessages.length > 0,
          'select-reference--hide-info': this.hideInfo,
          'select-reference--readonly': this.hideInfo
        }
      },
      joinedErrorMessages() {
        return this.errorMessages.join('<br/>');
      }
    },
    watch: {
      value() {
        this.refreshSelectedItem();
      }
    },
    methods: {
      getItemStyle(item) {
        if(!this.fieldColor) {
          return {};
        }
        const color = item[this.fieldColor] ? item[this.fieldColor] : '#fff';
        return {
          borderLeft: `5px solid ${color}`
        }
      },
      emitCreate(event) {
        this.$emit('create', event);
      },
      emitEdit(event, id) {
        event.stopPropagation();
        this.$emit('edit', id);
      },
      openDialog() {
        if(this.readonly) {
          return;
        }
        this.showDialog = true;
        this.refresh();
      },
      closeDialog() {
        this.showDialog = false;
      },
      emitSelection(item) {
        if(this.readonly) {
          return;
        }
        this.$emit('itemSelected', item);
        this.$emit('input', item !== null ? item[this.fieldValue] : null);
        this.closeDialog();
      },
      refreshDelayed(delay) {
        setTimeout(this.refresh, delay);
      },
      async refresh() {
        if(this.forceAvailableItems.length > 0) {
          this.availableItems = this.forceAvailableItems;
          return;
        }
        if (!this.endpoint) {
          this.showErrorNotifications('You have to provide an "endpoint". Where should the data come from huh? From god?');
          return;
        }
        const params = this.getPaginationParams();

        if(this.endpointParams) {
          Object.keys(this.endpointParams).forEach(paramKey => {
            params[paramKey] = this.endpointParams[paramKey];
          })
        }console.log(params);

        this.loading = true;
        apiSecured.get(this.endpoint, {
          params
        }).then((res) => {
          this.availableItems = [];
          this.availableItems = res.data;
          this.updatePagination(res);
          this.loading = false;
          this.showInfoNotification(this.__('common.data_loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async refreshSelectedItem() {
        if(this.forceAvailableItems.length > 0) {
          this.selectedItem = this.forceAvailableItems.find(item => item[this.fieldValue] === this.value);
          return;
        }
        if (!this.endpoint) {
          this.showErrorNotifications('You have to provide an "endpoint". Where should the data come from huh? From god?');
          return;
        }
        this.selectedItem = {};
        if(this.value !== null) {
          this.loading = true;
          await apiSecured.get(`${this.endpoint}/${this.value}`).then((res) => {
            this.selectedItem = res.data;
            this.loading = false;
            this.showInfoNotification(this.__('common.data_loaded'));
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error)
          });
        }
      }
    },
    created() {
      this.refreshSelectedItem();
    }
  }
</script>