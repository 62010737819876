<template>
  <div class="multi-select">
    <progress-linear v-if="loadingInternal"/>
    <div class="multi-select__selected">
      <div class="multi-select__selected-item" v-for="selectedItem in selectedItems">
        <div class="multi-select__selected-item-icon" v-if="fieldIcon" :style="{backgroundImage: `url(${getIcon(selectedItem)})`}"></div>
        <div class="multi-select__selected-item-title">{{getTitle(selectedItem)}}</div>
        <div class="multi-select__selected-item-remove" @click="emitDeselection(selectedItem)">
          <icon :icon="'bin'"/>
        </div>
      </div>
      <div class="multi-select__action" :title="__('common.add_an_item')" @click="openDialog()">
        <icon :icon="'add'"/>
      </div>
      <div class="multi-select__prepend-icon" :title="__('common.unsaved_changes')" v-if="prependIcon">
        <icon :icon="prependIcon"/>
      </div>
    </div>
    <my-dialog :show="showDialog" :max-width="'400px'">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('common.select_one_or_more')}}</div>
          <div class="card__button" @click="closeDialog" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="multi-select__selected">
          <progress-linear v-if="loadingInternal"/>
          <div class="multi-select__selected-item" v-for="selectedItem in selectedItems">
            <div class="multi-select__selected-item-icon" v-if="fieldIcon" :style="{backgroundImage: `url(${getIcon(selectedItem)})`}"></div>
            <div class="multi-select__selected-item-title">{{getTitle(selectedItem)}}</div>
            <div class="multi-select__selected-item-remove"
                 :title="__('common.remove_this_item')"
                 @click="emitDeselection(selectedItem)"
            >
              <icon :icon="'bin'"/>
            </div>
          </div>
          <div class="multi-select__prepend-icon" :title="__('common.unsaved_changes')" v-if="prependIcon">
            <icon :icon="prependIcon"/>
          </div>
        </div>
        <div class="card__content">
          <text-field
              v-model="searchTerm"
              :label="__('common.search_term')"
              maxlength="50"
              :hide-details="true"
              @keyup:enter="refresh"
          />
          <div class="selection-list" v-if="filteredItems.length > 0">
            <div class="selection-list__item" v-for="item in filteredItems" v-slashes @click="emitSelection(item)">
              <div class="selection-list__item-icon" v-if="getIcon(item)">
                <img :src="getIcon(item)" alt="-"/>
              </div>
              <div class="selection-list__item-title">
                {{ getTitle(item) }}
              </div>
            </div>
          </div>
          <p class="mt" v-else>{{__('component.ingame.common.item_browser.select_item_first')}}</p>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import apiSecured from '../../api/secured'
  import {notificationsMixin} from '../../mixins/notifications';
  import {localizationMixin} from '../../mixins/localization';

  export default {
    name: "MultiSelect",
    mixins: [notificationsMixin, localizationMixin],
    props: {
      value: {
        type: Array,
        default: () => []
      },
      prependIcon: {
        type: String,
        default: null
      },
      endpoint: {
        type: String,
        default: null
      },
      fieldValue: {
        type: String,
        default: 'id'
      },
      fieldTitle: {
        type: String,
        default: 'name'
      },
      fieldIcon: {
        type: String,
        default: null
      },
      fieldIconDefault: {
        type: String,
        default: 'https://cdn.hyperion-corporation.de/ingame/iconsLib/talentslib/missing.svg'
      }
    },
    data: () => ({
      loadingInternal: false,
      availableItems: [],
      selectedItems: [],
      searchTerm: '',
      showDialog: false
    }),
    created() {
      this.refreshSelectedItems();
    },
    watch: {
      value() {
        this.refreshSelectedItems();
      }
    },
    computed: {
      filteredItems() {
        return this.availableItems.filter(availableItem => !this.value.includes(availableItem.id));
      }
    },
    methods: {
      getValue(item) {
        if (this.fieldValue in item) {
          return item[this.fieldValue];
        }
        return item;
      },
      getTitle(item) {
        if (this.fieldTitle in item) {
          return item[this.fieldTitle];
        }
        return item;
      },
      getIcon(item) {
        if (this.fieldIcon in item) {
          return item[this.fieldIcon] ? item[this.fieldIcon] : this.fieldIconDefault;
        }
        return null;
      },
      openDialog() {
        this.showDialog = true;
        this.refresh();
      },
      closeDialog() {
        this.showDialog = false;
      },
      emitSelection(item) {
        this.$emit('itemSelected', item);
        this.value = [...this.value, item[this.fieldValue]];
        this.$emit('input', this.value);
        this.$forceUpdate();
      },
      emitDeselection(item) {
        this.$emit('itemDeselected', item);
        const itemIndex = this.value.findIndex(selectedItem => selectedItem === item[this.fieldValue]);
        this.value = this.value.filter((item, index) => itemIndex !== index);
        this.$emit('input', this.value);
        this.$forceUpdate();
      },
      refresh() {
        if (!this.endpoint) {
          this.showErrorNotifications('You have to provide an "endpoint" an "endpointField". Where should the data come from huh? From god?');
          return;
        }
        this.loadingInternal = true;
        this.availableItems = [];
        apiSecured.get(this.endpoint, {
          params: {
            searchQuery: this.searchTerm,
          }
        }).then((res) => {
          this.availableItems = res.data;
          this.loadingInternal = false;
          this.showInfoNotification(this.__('common.data_loaded'));
        }).catch((error) => {
          this.loadingInternal = false;
          this.showErrorNotifications(error)
        });
      },
      refreshSelectedItems() {
        if (!this.endpoint) {
          this.showErrorNotifications('You have to provide an "endpoint" an "endpointField". Where should the data come from huh? From god?');
          return;
        }
        if(this.value.length === 0) {
          this.selectedItems = [];
          return;
        }
        this.loadingInternal = true;
        apiSecured.get(`${this.endpoint}/(${this.value.join(',')})`).then((res) => {
          this.selectedItems = this.value.map(value => res.data.find(record => record.id === value));
          this.loadingInternal = false;
          this.showInfoNotification(this.__('common.data_loaded'));
        }).catch((error) => {
          this.loadingInternal = false;
          this.showErrorNotifications(error)
        });
      }
    }
  }
</script>