<template>
  <div class="aside" ref="aside" :class="{'aside--open': show}">
      <div class="aside__overlay" :key="'overlay'" @click="close"></div>
      <div class="aside__content" :style="{'max-width': maxWidth}" :key="'content'">
        <slot></slot>
      </div>
  </div>
</template>

<script>

  export default {
    name: "MyAside",
    props: {
      show: {
        type: Boolean,
        default: false
      },
      maxWidth: {
        type: String,
        default: '600px'
      }
    },
    data: () => ({}),
    methods: {
      close() {
          this.$emit('close')
      }
    },
    beforeMount() {
      this.$nextTick(() => {
        const target = document.getElementById('asides');
        target.appendChild(
          this.$refs.aside
        )
      })
    },
  }
</script>
