<template>
  <div class="application">
    <div class="background">
      <video id="bg_video" class="hidden-xs" poster="https://d5u0rv9n5kjyc.cloudfront.net/media/filer_public/8e/6c/8e6cb6fc-4a2b-4d5e-86f6-8d2bffcd40d2/header.png" src="https://d5u0rv9n5kjyc.cloudfront.net/media/filer_public/43/d5/43d595dd-a721-4e7c-a4bb-165c451a76ca/header.webm" autoplay="" loop="" muted=""></video>
    </div>
    <div class="content">
      <template v-if="showLogin()">
        <router-view/>
      </template>
      <template v-else>
        <main-menu :show-menu="showMainMenu" @close="showMainMenu = false"/>
        <router-view/>
      </template>
    </div>
    <div class="footer">
      <router-link :to="{name: 'dashboard'}" v-slashes>Home</router-link>
      <router-link :to="{name: 'privacy_policy'}" v-slashes>{{__('common.privacyPolicy')}}</router-link>
      <router-link :to="{name: 'credits'}" v-slashes>Credits</router-link>
      <a href="https://discord.gg/5MMxacV" target="_blank" v-slashes><img src="https://cdn.hyperion-corporation.de/ui/svgs/Discord-Logo-Wite.svg" alt="Visit our discord server" title="Visit our discord server"/></a>
      <a href="https://www.youtube.com/channel/UCbtGTuWRWevmskd3ofHgphA" target="_blank" v-slashes><img src="https://cdn.hyperion-corporation.de/ui/svgs/youtube.svg" alt="Visit our youtube channel" title="Visit our youtube channel"/></a>
      <a href="https://twitter.com/HYPCorporation" target="_blank" v-slashes><img src="https://cdn.hyperion-corporation.de/ui/svgs/Twitter_Logo_WiteOnImage.svg" alt="Visit us on Twitter" title="Visit us on Twitter"/></a>
<!--      <a href="ts3server://hyperion-corporation.de" target="_blank"><img src="https://cdn.hyperion-corporation.de/ui/svgs/teamspeak.svg" alt="Visit our teamspeak server" title="Visit our teamspeak server"/></a>-->
      <p>The "Dual Universe" brand, the "Dual Universe" logo and all associated logos and designs are the intellectual property of Novaquark S.A.S. Permission have been granted to https://hq.hyperion-corporation.de to use "Dual Universe" as a name and all associated logos and designs for promotional and information purposes only. https://hq.hyperion-corporation.de is a fansite that Novaquark S.A.S. does not endorse, and the latter is not affiliated with it in any way.</p>
    </div>
    <notifications/>
    <div id="dialogs"></div>
    <div id="asides"></div>
  </div>
</template>
<script>
  import Login from './components/Auth/Login';
  import Notifications from './components/Notifications';
  import MainMenu from './components/MainMenu'
  import { localizationMixin } from "./mixins/localization";
  import apiSecured from "./api/secured";
  // import { newsHub } from './api/signalR';

  export default {
    components: {
      Login,
      Notifications,
      MainMenu
    },
    mixins: [
      localizationMixin
    ],
    data() {
      return {
        showMainMenu: false,
      }
    },
    watch: {
      user() {
        if(!this.user) {
          this.$router.push({name: 'login'});
        }
      },
    },
    computed: {
      user() {
        return this.$store.state.user;
      }
    },
    methods: {
      showLogin() {
        if (!this.$store.state.user && this.$route.meta.requiresAuth) {
          return true;
        }
        return false;
      },
      addOrgCrawler() {
        apiSecured.get('/BackgroundJob/AddJobOrgCrawler').then(async (res) => {
          console.log(res.data);
        }).catch((error) => {
          console.log(error);
        });
      },
      getActiveJob() {
        apiSecured.get('/BackgroundJob/getActive').then(async (res) => {
          console.log(res.data);
        }).catch((error) => {
          console.log(error);
        });
      },
    },
    created() {
      this.$notification.requestPermission();

      this.$store.commit('setUser');
      window.addEventListener('focus', () => {
        const video = document.getElementById('bg_video');
        video.play();
      });
      window.addEventListener('blur', () => {
        const video = document.getElementById('bg_video');
        video.pause();
      });

      // newsHub.on("sendNews", data => {
      //   this.$notification.show(data.title, {
      //     body: data.bodytext
      //   }, {})
      // });
      //
      // newsHub.start()
      //     .catch(error => console.log(error));
      window.addOrgCrawler = this.addOrgCrawler;
      window.getActiveJob = this.getActiveJob;
    },
  }
</script>

<style lang="scss">
  @import "styles/index";
</style>
