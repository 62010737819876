import Vue from 'vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueTour from 'vue-tour';
import VueScrollTo from 'vue-scrollto';
import VueNativeNotification from 'vue-native-notification'
require('vue2-animate/dist/vue2-animate.min.css');
require('vue-tour/dist/vue-tour.css');

import App from './App.vue';
import router from './router';
import store from './store';
import Icon from './components/Global/Icon';
import ProgressLinear from './components/Global/ProgressLinear';
import MyDialog from './components/Global/MyDialog';
import Pagination from "./components/Global/Pagination";
import TextField from "./components/Global/TextField";
import TextArea from "./components/Global/TextArea";
import Switch from "./components/Global/Switch";
import SingleSelect from "./components/Global/SingleSelect";
import MultiSelect from "./components/Global/MultiSelect";
import SelectReference from "./components/Global/SelectReference";
import Slashes from "./directives/Slashes";
import DateTimeWrapper from "./components/Global/DateTimeWrapper";
import UniversalBrowser from './components/Global/UniveralBrowser';
import MyAside from "./components/Global/MyAside";
import MultiSelectFlat from "./components/Global/MultiSelectFlat";
import Tabs from "./components/Global/Tabs";

import "@fontsource/nunito";
import "@fontsource/nunito/200.css";
import "@fontsource/nunito/300.css";
import "@fontsource/nunito/600.css";
import "@fontsource/nunito/700.css";
import "@fontsource/nunito/900.css";

import "@fontsource/orbitron";
import "@fontsource/orbitron/700.css";

Vue.use(CKEditor);
Vue.use(VueTour);
Vue.use(VueScrollTo);
Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true
});


Vue.component('icon', Icon);
Vue.component('progress-linear', ProgressLinear);
Vue.component('my-dialog', MyDialog);
Vue.component('pagination', Pagination);
Vue.component('text-field', TextField);
Vue.component('text-area', TextArea);
Vue.component('my-switch', Switch);
Vue.component('single-select', SingleSelect);
Vue.component('multi-select', MultiSelect);
Vue.component('multi-select-flat', MultiSelectFlat);
Vue.component('select-reference', SelectReference);
Vue.component('date-time-wrapper', DateTimeWrapper);
Vue.component('my-aside', MyAside);
Vue.component('universal-browser', UniversalBrowser);
Vue.component('tabs', Tabs);
Vue.directive('slashes', Slashes);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
