<template>
  <div class="icon" :style="{ 'background-image': `url(${iconPath})` }"></div>
</template>

<script>
  const basePath = 'https://cdn.hyperion-corporation.de/ui/svgs/';

  const icons = {
    'add': 'add',
    'asterisk': 'asterisk',
    'bin': 'bin',
    'change-log': 'change-log',
    'check': 'check',
    'chevron-left': 'chevron-left',
    'chevron-right': 'chevron-right',
    'clock': 'clock',
    'context-menu': 'context_menu',
    'copy': 'copy',
    'cross': 'cross',
    'email': 'email',
    'error': 'error',
    'exclamation': 'exclamation',
    'eye': 'eye',
    'eye-off': 'eye_off',
    'file-blank': 'file_blank',
    'file-video': 'file_video',
    'flowchart': 'flowchart',
    'folder': 'folder',
    'folder-open': 'folder_open',
    'info': 'info',
    'list-point': 'list-point',
    'lock': 'lock',
    'open-in-new': 'open_in_new',
    'pencil': 'pencil',
    'source-branch': 'source_branch',
    'switch-off': 'switch_off',
    'switch-on': 'switch_on',
    'triangle-up': 'triangle-up',
    'gitlab': 'gitlab-icon-1-color-white-rgb',
    'heart': 'heart',
  };

  export default {
    name: "icon",
    props: ['block', 'icon'],
    computed: {
      iconPath() {
        return basePath + icons[this.icon] + '.svg';
      }
    }
  }
</script>