export default {
  // common
  'common.privacy_policy_hint': 'With the usage of this website you agree to our privacy policy.',
  'common.privacy_policy_agreed': 'I have read and accepted the privacy policy!',
  'common.privacyPolicy': 'Privacy Policy',
  'common.giveitatry': 'Give it a try!',
  'common.close': 'Close',
  'common.deleted': 'Deleted',
  'common.token_invalid': 'This token is invalid.',
  'common.save_changes': 'Save changes',
  'common.roles_loaded': 'Roles loaded',
  'common.groups_loaded': 'Groups loaded',
  'common.users_loaded': 'Users loaded',
  'common.items_loaded': 'Items loaded',
  'common.ores_loaded': 'Ores loaded',
  'common.recipes_loaded': 'Recipes loaded',
  'common.scan_result_resources_loaded': 'Scan result resources loaded',
  'common.scan_result_loaded': 'Scan result loaded',
  'common.ore_appearances_loaded': 'Ore appearances loaded',
  'common.sections_loaded': 'Sections loaded',
  'common.organizations_loaded': 'Organizations loaded',
  'common.stellar_objects_loaded': 'Stellar objects loaded',
  'common.skill_loaded': 'Skill loaded',
  'common.skill_groups_loaded': 'Skill groups loaded',
  'common.skill_group_loaded': 'Skill group loaded',
  'common.constructs_loaded': 'Constructs loaded',
  'common.players_loaded': 'Players loaded',
  'common.change_logs_loaded': 'Change logs loaded',
  'common.quotes_loaded': 'Quotes loaded',
  'common.price_logs_loaded': 'Price logs loaded',
  'common.projects_loaded': 'Projects loaded',
  'common.project_comments_loaded': 'Project comments loaded',
  'common.no_user_selected': 'No user selected.',
  'common.no_group_selected': 'No group selected.',
  'common.no_section_selected': 'No section selected.',
  'common.no_stellar_object_selected': 'No stellar object selected.',
  'common.no_item_selected': 'No item selected.',
  'common.no_ore_selected': 'No ore selected.',
  'common.no_project_type_selected': 'No project type selected.',
  'common.no_skill_group_selected': 'No skill group selected.',
  'common.select_user': 'Select user',
  'common.select_group': 'Select group',
  'common.search_term': 'Search term',
  'common.min_three_chars': 'Please provide at least 3 chars.',
  'common.create': 'Create',
  'common.refresh': 'Refresh',
  'common.edit_properties': 'Edit properties',
  'common.organizations': 'Organizations',
  'common.skill_levels': 'Skill levels',
  'common.menu': 'Menu',
  'common.logout': 'Logout',
  'common.file_browser': 'File browser',
  'common.skill_tree': 'Skill tree',
  'common.skill_tree_split': 'Skill tree',
  'common.crafting_calculator': 'Crafting Calculator',
  'common.members': 'Members',
  'common.org_chart': 'Org Chart',
  'common.total': 'Total',
  'common.add': 'Add',
  'common.delete': 'Delete',
  'common.confirmed': 'Confirmed',
  'common.are_you_sure': 'Are you sure?',
  'common.do_you_want_to_delete': 'Do you really want to permanently delete this record from the server?',
  'common.yes_delete_it': 'Yes, delete it!',
  'common.show_all': '-- show all --',
  'common.data_loaded': 'Data has been reloaded',
  'common.not_email_username': 'Not your email, your username.',
  'common.hint_confirmation_token': 'The token you got in an email. Must be 200 chars long.',
  'common.select_one_or_more': 'Select one or more',
  'common.remove_this_item': 'Remove this item',
  'common.add_an_item': 'Add an item',
  'common.unsaved_changes': 'Unsaved changes',
  'common.no_reference_selected': 'No reference selected',
  'common.unsaved_changes_warning': 'There are unsaved changes. If you contiue, they will be lost.',
  'common.just_du_it': 'Just du it!',
  'common.back': 'Back',
  'common.cancel': 'Cancel',
  'common.comment': 'Comment',
  'common.statistics': 'Statistics',
  'common.edit': 'Edit',
  'common.add_user_to_role_question': 'Do you really want to add the user <span class="text--primary">$user</span> to the role <span class="text--primary">$role</span>?',
  'common.remove_user_from_role_question': 'Do you really want to remove the user <span class="text--primary">$user</span> from the role <span class="text--primary">$role</span>?',
  'common.saved': 'Saved',
  'common.show_hidden': 'Show hidden',
  'organizationMemberships': 'Organization Memberships',
  'joinedAt': 'Joined at',
  'leftAt': 'Left at',
  'addMembership': 'Add membership',
  'organizationLogs': 'Organization logs',
  'playerLogs': 'Player Logs',
  'showPlayerLogs': 'Show player logs',
  'organizationMembershipLogs': 'Organization Membership Logs',
  'filterByPlayer': 'Filter by player',
  'filterByChildOrganization': 'Filter by child organization',
  'filterByOrganization': 'Filter by organization',
  'filterByOre': 'Filter by ore',
  'filterByUser': 'Filter by user',
  'filterByStellarObject': 'Filter by stellar object',
  'player': 'Player',
  'organization': 'Organization',
  'childOrganization': 'Child-Organization',
  'membershipType': 'Membership type',
  'copyUri': 'Copy uri to clipboard',
  'uriCopied': 'Uri copied to clipboard',
  'fullIngredientCalculationExplanation': 'Consider all follow up recipes and this ingredient\'s crafting time (default).',
  'ignoreFollowUpExplanation': 'Ignore the follow up recipes but consider this ingredient\'s crafting time. Use this config, if you have everything you need to craft this ingredient but you still have to craft the ingredient item.',
  'ignoreFollowUpAndCraftingTimeExplanation': 'Ignore the follow up recipes and this ingredient\'s crafting time. Use this config, if you have the ingredient item already in the required amount.',
  'ignoreIngredientConfigurations': 'Ignore ingredient configs',
  'reportOreAppearance': 'Report ore appearance',
  'reportOreAppearanceExplanation': '<p>Report here which ore you found on which planet or moon. Please consider that the <strong class="text--cyan">depth is measured from the surface</strong> and not from the altitude on the top right corner in the game.</p><p><strong>Example:</strong><br/>The <span class="text--cyan">surface altitude is 20 meters</span> and you found the ore at an altitude of <span class="text--cyan">-200 meters</span>, the ore apperance has to be reported at an altitude of <span class="text--cyan">-220 meters</span>.</p><p>You can report a range from the highest to the lowest appearance or if you just want to report one altitude, set highest and lowest to the same value.</p><p>Our content editors will review your report and merge or decline it. If your report gets declined, please don\'t be offended. There is surely a reason for that.</p><p>Thank you for participating!</p>',
  'thankYou': 'Thank you!',
  'sayThankYouForOreAppearanceReport': '<p>Thank you for your report <span class="text--cyan">$user</span>. We will review your data as soon as possible.</p>',
  'oreAppearanceReports': 'Ore Appearance Reports',
  'oreAppearanceReportsSplit': 'Ore Appear-ance Reports',
  'editOreAppearanceReport': 'Edit ore appearance report',
  'merge': 'merge',
  'merged': 'merged',
  'decline': 'decline',
  'declined': 'declined',
  'highestAlt': 'Highest Alt.',
  'lowestAlt': 'Lowest Alt.',
  'oreAppearanceReportDoYouWantToMerge': 'Do you really want to merge this ore appearance report?',
  'oreAppearanceReportDoYouWantToDecline': 'Do you really want to decline this ore appearance report?',
  'size': 'Size',
  'onlyDirect': 'Only direct',
  'itemReports': 'Item reports',
  'filterByItem': 'Filter by item',
  'reportItemExplanation': '<p>Here you can report if something with an item is wrong. This can be data in a property of the item itself if the crafting recipe changed.</p><p>Please keep in mind, that the item properties and the recipes are stored without any influence of skills. The skills will be used in the calculation later.</p>',
  'itemReportDoYouWantToMerge': 'Do you really want to merge this item report?',
  'itemReportDoYouWantToDecline': 'Do you really want to decline this item report?',
  'editItemReport': 'Edit item report',
  'editItem': 'Edit item',
  'reportItem': 'Report item',
  'filterByGroup': 'Filter by group',
  'showAll': 'Show all',
  'editNews': 'Edit news',
  'meters': 'meters',
  'seconds': 'seconds',
  'skip': 'Skip tour',
  'previous': 'Previous',
  'next': 'Next',
  'finish': 'Finish',
  'mass': 'Total Mass',
  'craftingTime': 'Crafting Time',
  'linearCraftingTime': 'Linear Crafting Time',
  'oreBuyValue': 'Ore Buy Value',
  'oreSellValue': 'Ore Sell Value',
  'endProducts': 'End Products',
  'totals': 'Totals',
  'questionDeleteEndProduct': 'Do you want to remove this endproduct from the crafting list?',
  'craftingList': 'Crafting List',
  'editCraftingList': 'Edit Crafting List',
  'save': 'Save',
  'addAnEndProduct': 'Add an end product',
  'createCraftingListFirst': 'Please create the crafting list first and add some end products.',
  'craftingLists': 'Crafting Lists',
  'createACraftingList': 'Create a crafting list',
  'sorting': 'Sorting',
  'pleaseSelectItem': 'Please select an item',
  'amountTooSmall': 'Amount must be greater than 0',
  'browserHint': 'Firefox causes some trouble currently so we recomment you to use a Chromium based browser for optimal visualizations and functionality like Chrome, Opera, Vivaldi or the newest Edge. We don\'t recommend a specific one. The choice is yours.',
  'cookieDisclaimer': 'Our website uses cookies to handle several functional features, like page traffic, single sign on and personal configurations. You need to allow the usage of cookies to be able to login to our services. You can always check the used cookies in the developer tools of your browser.',
  'cookieQuestion': 'I allow the usage of cookies.',
  'isFaction': 'Is faction',
  'clickToSeeInfoAndPlayers': 'Click to see who unlocked it',
  'members': 'Members',
  'library': 'Library',
  'valuesForOneQubicMeter': 'Values for 1 m³',
  'itemVariationsLibrary': 'Item Variation Library',
  'newton': 'newton',
  'literPerKilonewton': 'liter per kilonewton',
  'literPerHour': 'liter per hour',
  'secondsToT50Thrust': 'seconds to 50% thrust',
  'parentSkillGroup': 'Parent Skill-Group',
  'noParentSkillGroupSelected': 'No parent skill-group selected',
  'credentialsHint': 'You DON\'T have to use your Dual Universe login. You can use ANY email, username and password you want!',
  'public': 'public',
  'projectImages': 'Project images',
  'selectProjectImages': 'Select project images',
  'addProjectImages': 'Add project images / videos',
  'editProjectImagesHint1': 'You can order the project images by dragging one image or video. This is only enabled, if there is no new unsaved project image or video and no one that has been marked to be deleted.',
  'editProjectImagesHint2': 'You can add multiple new project images and videos by clicking on the plus icon. This is only enabled, if you don\'t have unsaved sorting changes.',
  'editProjectImagesHint3': 'You can delete multiple project images and videos from the project by clicking on the bin icon. This will not delete the actual file. This is only enabled, if you don\'t have unsaved sorting changes.',
  'filterByProjectType': 'Filter by project type',
  'filterByProjectState': 'Filter by project state',
  'playerRegistration': 'Player Registration',
  'playerRegistrationExplanation': '<p>To be able to use several player related features like the skill tree, you have connect your player(s) with your user account.</p><p>To do this, go to a <span class="text--teal">Surrogate Station</span> and select the target <span class="text--teal">Hyperium Player Registration</span>. Surrogate Stations can be found on each market. Please see the screenshot above this text.</p><p>Follow the instructions you find there. You will have to type in the token you see below this text.</p>',
  'addPlayer': 'Add player',
  'playerRegistrationLogs': 'Player registration logs',
  'successful': 'Successful',
  'message': 'Message',
  'manageOrganizations': 'Manage organizations',
  'enableOrganizationView': 'Enable organization view',
  'organizationViewExplanation': '<img style="height: auto; width: 100%;" src="https://cdn.hyperion-corporation.de/ui/organization-view.jpg"/><p>The <span class="text--teal">organizations view</span> gives you an overview of the skills of every member of your organizations.</p><p>It has to be enabled for each organization seperately and can only be done by a <span class="text--teal">(super)legate</span> of the organization.</p><p>The list below shows you all organizations, where you have players to your account connected and that are at least a legate in that organization.</p><p>If you enable this feature for your organization, <span class="text--teal">every member of that organization</span> can see the skills of all members of that organization, if they have their players connected to their account.</p><p>You can connect players to your account with the <span class="text--teal">Add player</span> button in the skill tree.</p><p>If you don\'t see any organization in the list below, you are not a (super)legate in any organization you are part of. In this case, ask one of your legates to activate the organization view for you.</p>',
  'vQuantaPrice': 'vQuanta Price',
  'vQuantaPriceTotal': 'Total vQuanta Price',
  'A': 'A',
  'TCT': 'TCT',
  'TLCT': 'TLCT',
  'RCT': 'RCT',
  'RLCT': 'RLCT',
  'BS': 'BS/BC',
  'OPH': 'OPH',
  'MOPH': 'MOPH',
  'CPH': 'CPH',
  'RI': 'RI',
  'RIA': 'RIA',
  'showName': 'Show Name',
  'showIcon': 'Show Icon',
  'showAmount': 'Show Amount',
  'showTCT': 'Show Total Crafting Time',
  'showTLCT': 'Show Total Linear Crafting Time',
  'showRCT': 'Show Recursive Crafting Time',
  'showRLCT': 'Show Recursive Linear Crafting Time',
  'showBS': 'Show Batch Size / Count',
  'showOPH': 'Show Output per Hour',
  'showMOPH': 'Show Max Output per Hour',
  'showCPH': 'Show Consumpter per Hour',
  'showRI': 'Show Required Industries',
  'showRIA': 'Show Recommended Industries Amount',
  'showOptions': 'show options',
  'hideOptions': 'hide options',
  'amount': 'Amount',
  'endIndustriesAmount': 'End-Industries Amount',
  'tctLegend': 'Total Crafting Time (Total Linear Crafting Time / Recommended Industries Amount)',
  'tlctLegend': 'Total Linear Crafting Time (Crafting Time * Amount)',
  'rctLegend': 'Recursive Crafting Time (TCT with children)',
  'rlctLegend': 'Recursive Linear Crafting Time (TLCT with children)',
  'bsLegend': 'Batch Size / Batch Count',
  'ophLegend': 'Output per Hour, how many of this item can be produced in one hour by one industry',
  'mophLegend': 'Max Output per Hour, how many of this item can be produced in one hour by the recommended amount of industries',
  'cphLegend': 'Consumption per Hour, how many of this item is consumed in one hour by it\'s parent node. The calculation begins with the max output per hour of the first node in the tree.',
  'riLegend': 'Required Industry',
  'riaLegend': 'Recommended Industries Amount',
  'showCraftingTree': 'Show Crafting Tree',
  'expandTree': 'Expand Tree',
  'collapseTree': 'Collapse Tree',
  'hintHorizontalScroll': 'Use <span class="text--teal text--code">Shift</span> + <span class="text--teal text--code">Mousewheel</span> to scroll horizontally.',

  'shipImages': 'Ship images',
  'selectShipImages': 'Select ship images',
  'addShipImages': 'Add ship images / videos',
  'editShipImagesHint1': 'You can order the ship images by dragging one image or video. This is only enabled, if there is no new unsaved ship image or video and no one that has been marked to be deleted.',
  'editShipImagesHint2': 'You can add multiple new ship images and videos by clicking on the plus icon. This is only enabled, if you don\'t have unsaved sorting changes.',
  'editShipImagesHint3': 'You can delete multiple ship images and videos from the ship by clicking on the bin icon. This will not delete the actual file. This is only enabled, if you don\'t have unsaved sorting changes.',
  'shipClass': 'Ship class',
  'noShipClassSelected': 'No ship class selected',
  'coreSize': 'Core size',
  'noOrganizationSelected': 'No organization selected',
  'ships': 'Ships',
  'editShipClass': 'Edit ship class',
  'color': 'Color',
  'shipClasses': 'Ship classes',
  'editShip': 'Edit ship',
  'filterByShipClass': 'Filter by ship class',
  'filterByContributor': 'Filter by contributor',
  'filterByCoreSize': 'Filter by core size',
  'thxForLove': 'Thank you for your love!',
  'brokenHeart': 'One more broken heart...',
  'hidden': 'hidden',
  'scale': 'scale',
  'bot_price': 'Bot price',
  'requiredIndustries': 'Required industries',
  'open': 'open',
  'in-progress': 'in progress',
  'done': 'done',
  'cancelled': 'cancelled',
  'state': 'State',
  'role': 'Role',
  'no_role_selected': 'No role selected',
  'assignedTo': 'Assigned to',
  'restrictViewAccessTo': 'Restrict view access to',
  'editTodo': 'Edit todo',
  'creator': 'Creator',
  'notAssigned': 'not assigned',
  'edit': 'Edit',
  'assignToMe': 'Assign to me',
  'letMeDuIt': 'Let me DU it',
  'assignToMeQuestionTitle': 'Do you really want to assign this todo to you?',
  'assignToMeDefaultQuestionText': '<p>If you take this responsibility, people will count on you.</p><p>So please take it serious and complete the task with <span class="text--love">passion</span>.</p>',
  'assignToMeConflictQuestionText': '<p class="text--uppercase text--error">Important</p><p>This todo is already assigned to <span class="text--teal">$username</span>.</p><p>Please take this todo only if you have talked to the responsible members.</p>',
  'myTodos': 'My todos',
  'priority': 'Priority',


  'tier': 'Tier',
  'hitpoints': 'Hitpoints',
  'resistancesAntimatter': 'Resistance Antimatter',
  'resistancesElectromagnetic': 'Resistance Electromagnetic',
  'resistancesKinetic': 'Resistance Kinetic',
  'resistancesThermic': 'Resistance Thermic',
  'resistancesAntimatterHp': 'Normalized Antimatter HP',
  'resistancesElectromagneticHp': 'Normalized Electromagnetic Hp',
  'resistancesKineticHp': 'Normalized Kinetic HP',
  'resistancesThermicHp': 'Normalized Thermic HP',
  'resistancesAntimatterHpPerMass': 'Normalized Antimatter HP / Mass',
  'resistancesElectromagneticHpPerMass': 'Normalized Electromagnetic Hp / Mass',
  'resistancesKineticHpPerMass': 'Normalized Kinetic HP / Mass',
  'resistancesThermicHpPerMass': 'Normalized Thermic HP / Mass',
  'clickForSortByRow': 'Click to sort by this row.',

  'tourTitleOre': 'Select an ore',
  'tourContentOre': `<div class="bodytext"><p><span class="text--teal">Click on an ore</span> to show only the stellar objects, where this ore has been discovered.</p><p>You can click on <span class="text--teal">multiple</span> ones and then all stellar objects are show, where at least one of the selected ores has been discovered.</p><p>By clicking on an ore again, you can remove the filter.</p></div>`,
  'tourTitleStellarObject': 'Select stellar object',
  'tourContentStellarObject': `<div class="bodytext"><p><span class="text--teal">Click on a stellar object</span> to highlight the row of it.</p><p>You can click on <span class="text--teal">multiple</span> ones.</p><p>By clicking on an stellar object again, you can remove the highlighting.</p></div>`,
  'tourTitleMoreInfo': 'More informations?',
  'tourContentStellarObjectDetails': `<div class="bodytext"><p><span class="text--teal">Click on the info icon</span> next to a stellar object to get more detailed informations about it. Like: Is there an atmosphere, how strong is the gravitational force, air density, etc.</p><p>This can become handy for your next mining run, because you don't want to get stuck on another planet.</p></div>`,
  'tourTitleBugDiscovery': 'A bug? New discovery?',
  'tourContentReportOreAppearance': `<div class="bodytext"><p>The ore appearances are maybe not correct or incomplete. So if you found something new you can always make an <span class="text--teal">ore appearance report</span>. Just click on this button and fill out the form. We will review the report and merge it as soon as possible.</p></div>`,

  'tourCCTitleBasicInfos': 'Basic infos',
  'tourCCContentBasicInfos': `<div class="bodytext"><p>Here you see the basic informations about an item together with its <span class="text--teal">categories description</span> and <span class="text--teal">item description</span> if available.</p></div>`,
  'tourCCTitleTotalCraftingTime': 'Total Crafting Time',
  'tourCCContentTotalCraftingTime': `<div class="bodytext"><p>This is the <span class="text--teal">time it takes</span> to craft this item and all it's ingredients in a row <span class="text--teal">without crafting some items at the same time</span>.</p></div>`,
  'tourCCTitleTotalOreSellValue': 'Total ore sell value',
  'tourCCContentTotalOreSellValue': `<div class="bodytext"><p>You can <span class="text--teal">sell each ore</span> to the <span class="text--teal">bot</span> at the market. This is the <span class="text--teal">amount of quanta</span> (the ingame currency) the bot would pay you, <span class="text--teal">if you would sell the ores</span>, that are required to craft this item.</p></div>`,
  'tourCCTitleRequiredIndustries': 'Required industries',
  'tourCCContentRequiredIndustries': `<div class="bodytext"><p>Smaller items can be crafted in the <span class="text--teal">nanoformer</span> but it will take the <span class="text--teal">double amount of time</span>. The best way is to use an <span class="text--teal">industry</span>. For larger or higher tier items it will actually require one. This shows the industry, that can craft this item. Some items can be crafted in multiple industries, <span class="text--teal">but you only need one</span>. Hover over an industry so see its name.</p></div>`,
  'tourCCTitleAmount': 'Amount',
  'tourCCContentAmount': `<div class="bodytext"><p>Here you can set <span class="text--teal">how much</span> you want to craft of this item. The <span class="text--teal">total values</span> and <span class="text--teal">ingredients</span> will be calculated <span class="text--teal">for this amount</span>.</p></div>`,
  'tourCCTitleCalculate': 'Calculate',
  'tourCCContentCalculate': `<div class="bodytext"><p>After changing the amount, remember to <span class="text--teal">recalculate</span> the item.</p></div>`,
  'tourCCTitleIgnoreIngredientConfigs': 'Ignore ingredient configs',
  'tourCCContentIgnoreIngredientConfigs': `<div class="bodytext"><p>You can <span class="text--teal">ignore ingredients in the calculations</span>. We will explain this later in this tour. If you want a <span class="text--teal">complete calculation</span>, that ignores every configuration, <span class="text--teal">toggle this option on</span>. If you want your configurations to be considered, <span class="text--teal">toggle it off</span>.</p></div>`,
  'tourCCTitleOnlyDirect': 'Only direct',
  'tourCCContentOnlyDirect': `<div class="bodytext"><p>The crafting result is calculated <span class="text--teal">recursively</span>. This means it includes the ingredients for all ingredients and their ingredients and so on. If you toggle this option on, <span class="text--teal">only direct ingredients will be calculated</span>. This can become handy, if you want to fill the required items in an input container of a factory f. e.</p></div>`,
  'tourCCTitlePriceLogs': 'Price logs',
  'tourCContentPriceLogs': `<div class="bodytext"><p>We will collect the market prices in the future and provide a <span class="text--teal">history of the prices</span>. This can be useful if you think about buying the item.</p></div>`,
  'tourCCTitleDataSheet': 'Data Sheet',
  'tourCCContentDataSheet': `<div class="bodytext"><p>This table shows all the details about an item. The values represent <span class="text--teal">one piece or one liter</span> of the current selected item. <span class="text--teal">Skills will not considered</span>. This is a raw data sheet.</p></div>`,
  'tourCCTitleIngredients': 'Ingredients',
  'tourCCContentIngredients': `<div class="bodytext"><p>This is the list of the parts, materials, etc. that are required to craft this item or ingredients of the item, so it's generated <span class="text--teal">recursively</span>.</p></div>`,
  'tourCCTitleIngredientGroups': 'Ingredient Groups',
  'tourCCContentIngredientGroups': `<div class="bodytext"><p>You can <span class="text--teal">filter the ingredients</span> by their <span class="text--teal">group</span>. The list of groups can vary depending on the ingredients in the list. By clicking on the left <span class="text--teal">asterisk</span> symbol, you can show <span class="text--teal">all</span> ingredients again.</p></div>`,
  'tourCCTitleIngredient': 'Ingredient',
  'tourCCContentIngredient': `<div class="bodytext"><p>Each row represents <span class="text--teal">one ingredient item</span>.</p></div>`,
  'tourCCTitleIngredientIcon': 'Ingredient Icon',
  'tourCCContentIngredientIcon': `<div class="bodytext"><p>This is the ingredient <span class="text--teal">icon</span></p></div>`,
  'tourCCTitleIngredientName': 'Ingredient Name',
  'tourCCContentIngredientName': `<div class="bodytext"><p>the <span class="text--teal">name</span> of the ingredient</p></div>`,
  'tourCCTitleIngredientTier': 'Ingredient Tier',
  'tourCCContentIngredientTier': `<div class="bodytext"><p>and the <span class="text--teal">tier</span> (level) of the ingredient item. Higher tiers require higher tier resources and will take longer crafting times.</p></div>`,
  'tourCCTitleIngredientAmount': 'Ingredient Amount',
  'tourCCContentIngredientAmount': `<div class="bodytext"><p>This is the required <span class="text--teal">amount</span> of the ingredient in <span class="text--teal">liter or pieces</span>.</p></div>`,
  'tourCCTitleIngredientCraftingTime': 'Crafting Time',
  'tourCCContentIngredientCraftingTime': `<div class="bodytext"><p>This is the <span class="text--teal">time</span> it takes to craft the required amount <span class="text--teal">with an industry</span>. Crafting it with the <span class="text--teal">nanoformer</span> will take the <span class="text--teal">double amount</span> of time.</p></div>`,
  'tourCCTitleIngredientConfigurations': 'Ingredient configurations',
  'tourCCContentIngredientConfigurations': `<div class="bodytext"><p>You can configure <span class="text--teal">how ingredients are calculated</span>, depending on different scenarios. We will explain you each scenario now. Remember, that you have to toggle the option <span class="text--teal">Ignore ingredient configurations</span> off or they will be ignored.</p></div>`,
  'tourCCTitleIngredientConfigurationFull': 'Full (default)',
  'tourCCContentIngredientConfigurationFull': `<div class="bodytext"><p>This is the <span class="text--teal">default mode</span>. It will calculate all sub ingredients and the own crafting time.</p></div>`,
  'tourCCTitleIngredientConfigurationIgnoreSub': 'Ignore sub ingredients',
  'tourCCContentIngredientConfigurationIgnoreSub': `<div class="bodytext"><p>In this mode the calculation will <span class="text--teal">ignore the sub ingredients</span> for this ingredient but it will <span class="text--teal">consider the crafting time of this ingredient</span> for the required amount. Use this configuration, if you have everything you need to craft this ingredient, but you still have to craft the ingredient itself in the required amount.</p></div>`,
  'tourCCTitleIngredientConfigurationIgnore': 'Ignore completely',
  'tourCCContentIngredientConfigurationIgnore': `<div class="bodytext"><p>In this mode the calculation will <span class="text--teal">ignore the whole ingredent</span> and <span class="text--teal">subingredients</span> and it's <span class="text--teal">crafting time</span>. Use this configuration, if you have the ingredient already in the required amount or for catalysts for expample, that you will get back while crafting.</p></div>`,
  'tourCCTitleIngredientIndustries': 'Required industries',
  'tourCCContentIngredientIndustries': `<div class="bodytext"><p>Here you see which <span class="text--teal">industry is required</span> to craft this item. Some items are craftable in <span class="text--teal">multiple</span> industries, but <span class="text--teal">you need only one</span>. Some items also maybe craftble in the <span class="text--teal">nanoformer</span>, but it will take the <span class="text--teal">double amount of time</span>. Hover over an industry so see its name.</p></div>`,
  'tourCCTitleUsages': 'Usages',
  'tourCCContentUsages': `<div class="bodytext"><p>This list shows for which items it is needed to craft them, like, <span class="text--teal">where it is an ingredient</span>. This is resolved <span class="text--teal">resursively</span>, but if you toggle the option <span class="text--teal">only direct</span> on, it will only show items, where it is directly an ingredient.</p></div>`,
  'tourCCTitleReport': 'Report item data and recipes',
  'tourCCContentReport': `<div class="bodytext">Unfortunately it can happen, that <span class="text--teal">data in the data sheet or crafting recipes got changed</span>, especially in the current phase of the game development. So if you find something wrong, <span class="text--teal">don't hesitate to report what you found</span>. We will investigate that item and correct it as soon as possible. <span class="text--teal">We thank everybody alot</span>, who helps us keeping the quality high!</div>`,


  'system': 'System',
  'antiGravMinAltitude': 'Min Antigrav Altitude',
  'atmosphericDensityAboveSurface': 'Atmo Density Above Surface',
  'atmosphericEngineMaxAltitude': 'Atmo Engine Max Altitude',
  'biosphere': 'Biosphäre',
  'bodyId': 'InGame Id',
  'classification': 'Classifikation',
  'discoveredBy': 'Discovered by',
  'fullAtmosphericDensityMaxAltitude': 'Full Atmo Density Max Altitude',
  'GM': 'Gravitational Constant X Mass',
  'gravity': 'Gravity',
  'habitability': 'Habitability',
  'hasAtmosphere': 'Has Atmosphere',
  'isSanctuary': 'Is Sanctuary',
  'noAtmosphericDensityAltitude': 'No Atmo Density Altitude',
  'numSatellites': 'Num Satellites',
  'positionFromSun': 'Position From Sun',
  'positionX': 'Position X',
  'positionY': 'Position Y',
  'positionZ': 'Position Z',
  'radius': 'Radius',
  'safeAreaEdgeAltitude': 'Safe Area Edge Altitude',
  'spaceEngineMinAltitude': 'Space Engine Min Altitude',
  'surfaceArea': 'Surface Area',
  'surfaceAverageAltitude': 'Surface Average Altitude',
  'surfaceMaxAltitude': 'Surface Max Altitude',
  'surfaceMinAltitude': 'Surface Min Altitude',
  'systemZone': 'System Zone',
  'territories': 'Territories',
  'type': 'Type',
  'waterLevel': 'Water Level',

  // entities common
  'entity.common.id': 'Id',
  'entity.common.title': 'Title',
  'entity.common.created_at': 'Created at',
  'entity.common.updated_at': 'Updated at',
  'entity.common.section': 'Section',
  'entity.common.sections': 'Sections',
  'entity.common.group': 'Group',
  'entity.common.groups': 'Groups',
  'entity.common.role': 'Role',
  'entity.common.roles': 'Roles',
  'entity.common.item': 'Item',
  'entity.common.items': 'Items',
  'entity.common.user': 'User',
  'entity.common.users': 'User',
  'entity.common.description': 'Description',
  'entity.common.subdescription': 'Subdescription',
  'entity.common.size': 'Size',
  'entity.common.position': 'Position',
  'entity.common.icon': 'Icon',
  'entity.common.volume': 'Volume',
  'entity.common.volume_in_liters': 'Volume in liters',
  'entity.common.stellar_object': 'Stellar object',
  'entity.common.stellar_objects': 'Stellar objects',
  'entity.common.stellar_objects_split': 'Stellar objects',
  'entity.common.need_review': 'Needs review',
  'entity.common.community_url_slug': 'Community url slug',
  'entity.common.name': 'Name',
  'entity.common.skill_group': 'Skill Group',
  'entity.common.sorting': 'Sorting',
  'entity.common.tier': 'Tier',
  'entity.common.change_logs': 'Change Logs',
  'entity.common.change_logs_split': 'Change Logs',
  'entity.common.scan_result': 'Scan result',
  'entity.common.scan_results': 'Scan results',
  'entity.common.scan_results_split': 'Scan results',
  'entity.common.organization': 'Organization',
  'entity.common.organizations': 'Organizations',
  'entity.common.organizations_split': 'Organi-zations',
  'entity.common.player': 'Player',
  'entity.common.players': 'Players',
  'entity.common.construct': 'Construct',
  'entity.common.constructs': 'Constructs',
  'entity.common.project': 'Project',
  'entity.common.projects': 'Projects',
  'entity.common.project_type': 'Project type',
  'entity.common.project_types': 'Projects types',
  'entity.common.ore': 'Ore',
  'entity.common.ore_appearance': 'Ore appearance',
  'entity.common.ore_appearances': 'Ore appearances',
  'entity.common.ore_appearances_split': 'Ore appear-ances',
  'entity.common.quote': 'Quote',
  'entity.common.quotes': 'Quotes',
  'entity.common.type': 'Type',
  'entity.common.tile': 'Tile',
  'entity.common.prime': 'Prime',
  'entity.common.vice': 'Vice',
  'entity.common.storage': 'Storage',
  'entity.common.storages': 'Storages',
  'entity.common.storageContent': 'Storage content',
  'entity.common.storageContents': 'Storages contents',
  'entity.common.storageTransaction': 'Storage transaction',
  'entity.common.storageTransactions': 'Storages transactions',
  'entity.common.hidden': 'Hidden',
  'entity.common.constructLogs': 'Construct logs',

  // entities
  'entity.auth.role.parent': 'Parent role',
  'entity.auth.role.gitlab_group_ids': 'Gitlab group ids',

  'entity.auth.user.username': 'Username',
  'entity.auth.user.username_canonical': 'Username canonical',
  'entity.auth.user.password': 'Password',
  'entity.auth.user.email': 'E-Mail',
  'entity.auth.user.email_canonical': 'E-Mail canonical',
  'entity.auth.user.email_confirmed': 'E-Mail confirmed',
  'entity.auth.user.enabled': 'Enabled',
  'entity.auth.user.super_admin': 'Super admin',
  'entity.auth.user.groups': 'Groups',
  'entity.auth.user.roles': 'Roles',
  'entity.auth.user.last_login': 'Last login',
  'entity.auth.user.confirmation_token': 'Confirmation token',
  'entity.auth.user.confirmation_token_expires': 'Confirmation token expires',
  'entity.auth.user.emailConfirmed': 'Email confirmed',

  'entity.ingame.ore_appearance.highest_altitude_from_surface': 'Highest altitude from surface',
  'entity.ingame.ore_appearance.lowest_altitude_from_surface': 'Lowest altitude from surface',

  'entity.ingame.organization.state': 'Diplomatic state',
  'entity.ingame.organization.state_short': 'State',
  'entity.ingame.organization.state.enemy': 'enemy',
  'entity.ingame.organization.state.neutral': 'neutral',
  'entity.ingame.organization.state.friendly': 'friendly',
  'entity.ingame.organization.state.ally': 'ally',
  'entity.ingame.organization.state.self': 'self',
  'entity.ingame.organization.ownerId': 'Owner Id',
  'entity.ingame.organization.foundedAt': 'Founded at',

  'entity.ingame.player.legate_in': 'Legate in',
  'entity.ingame.player.superlegate_in': 'Superlegate in',
  'entity.ingame.player.nq': 'Is von Novaquark',
  'entity.ingame.player.about': 'About',
  'entity.ingame.player.joined': 'Joined',
  'entity.ingame.player.founder_status': 'Founder status',
  'entity.ingame.player.location': 'Location',
  'entity.ingame.player.facebook': 'Facebook',
  'entity.ingame.player.twitter': 'Twitter',

  'entity.ingame.project.in_charge': 'In charge',
  'entity.ingame.project.state': 'State',
  'entity.ingame.project.contributors': 'Contributors',
  'entity.ingame.project.access': 'Read access',
  'entity.ingame.project.deadline': 'Deadline',
  'entity.ingame.project.state.concept': 'concept',
  'entity.ingame.project.state.in-progress': 'in progress',
  'entity.ingame.project.state.on-hold': 'on hold',
  'entity.ingame.project.state.testing': 'testing',
  'entity.ingame.project.state.cancelled': 'cancelled',
  'entity.ingame.project.state.ready': 'ready',

  'entity.ingame.storage.storage_content.minimum_amount': 'Minimum amount',
  'entity.ingame.storage.storage_content.target_amount': 'Target amount',
  'entity.ingame.storage.storage_content.maximum_amount': 'Maximum amount',
  'entity.ingame.storage.storage_content.resource_type_factor': 'Resource type factor',

  'entity.player_progression.skill_level.stage': 'Stage',
  'entity.player_progression.skill_level.required_points': 'Required points',
  'entity.player_progression.skill_level.required_skill_level': 'Required skill level',

  'entity.community.quote.quote': 'Quote',

  'entity.fal.file.filename': 'Filename',
  'entity.fal.file.path': 'Path',
  'entity.fal.file.view_access': 'View access',
  'entity.fal.file.mod_access': 'Mod access',
  'entity.fal.file.is_listed': 'Is listed',
  'entity.fal.file.owner': 'Owner',

  'entity.change_log.entity': 'Entity',
  'entity.change_log.entity_id': 'Entity id',
  'entity.change_log.changes': 'Changes',

  // messages
  'validation.too_short': 'The field $1 has to be at least $2 chars long.',
  'validation.too_long': 'The field $1 cannot be longer than $2 chars.',
  'validation.greater_than_zero': 'The field $1 has to be greater than 0.',
  'validation.vec3': 'The $1 has to look like this {"x":0.0,"y":0.0,"z":0.0}',
  'validation.cannot_be_empty': 'The field $1 cannot be empty.',

  // components
  'component.auth.confirm_email.title': 'Confirm E-Mail',
  'component.auth.confirm_email.explanation_1': 'Please check your email account. You have got an email with a token. Put your username and this token in the fields below and submit the form.',
  'component.auth.confirm_email.explanation_2': 'If you can\'t confirm your email because of an invalid token response, the token is maybe expired, because it will be valid for only 48 hours. In this case contact an admin, who will resend that email and generate you a new token.',

  'component.auth.edit_role.title': 'Edit group',
  'component.auth.edit_role.no_parent': '---no parent---',

  'component.auth.edit_myself.title': 'Edit myself',
  'component.auth.edit_myself.dll_authentification': 'DLL Authentification',
  'component.auth.edit_myself.copy_this_code': 'Copy this code',
  'component.auth.edit_myself.copy_to_clipboard': 'Copy to clipboard',
  'component.auth.edit_myself.avatar_url': 'Avatar url',
  'component.auth.edit_myself.new_password': 'New password',
  'component.auth.edit_myself.new_password_repeat': 'New password repeat',
  'component.auth.edit_myself.userdata_loaded': 'Userdata loaded',
  'component.auth.edit_myself.password_not_repeated': 'You have to repeat the password.',
  'component.auth.edit_myself.password_not_equal': 'The password repetition has to be equal.',
  'component.auth.edit_myself.copied_to_clipboard': 'Code copied to clipboard.',
  'component.auth.edit_myself.preferredLanguage': 'Preferred language',

  'component.auth.edit_user.title': 'Edit user',
  'component.auth.edit_user.password_not_repeated': 'You have to repeat the password.',
  'component.auth.edit_user.password_repetition_not_equal': 'The password repetition has to be equal.',
  'component.auth.edit_user.email_not_valid': 'This has to be a valid email address',

  'component.auth.login.login': 'Login',
  'component.auth.login.register': 'Register',
  'component.auth.login.confirm_email': 'Confirm E-Mail',
  'component.auth.login.welcome': 'Welcome',
  'component.auth.login.account_activated': 'You have fulfilled all your duties. You account is activated an you can login. If you have any problem, contact WildChild85 directly for support.',
  'component.auth.login.request_password_reset': 'Request password reset',
  'component.auth.login.reset_password': 'Reset password',
  'component.auth.login.request_password_reset_explanation': 'Please provide your username. We will send you an email with a reset token to the email address you provided us while registering. If you don\'t recieve an email, please contact us on discord.',
  'component.auth.login.check_inbox': 'Please check your inbox.',
  'component.auth.login.password_resetted': 'Password reset successful',

  'component.auth.register.title': 'Registration',

  'component.auth.select_avatar.title': 'Select avatar',
  'component.auth.select_avatar.filetype_not_supported': 'This file type is not supported.',

  'component.community.common.user_browser.search_first': 'Please search for users first.',
  'component.community.common.group_browser.search_first': 'Please search for groups first.',

  'component.community.edit_quote.title': 'Edit quote',

  'component.crafting_calculator.total_crafting_time': 'Total Crafting Time',
  'component.crafting_calculator.total_ore_sell_value': 'Total Ore Sell Value',
  'component.crafting_calculator.total_ore_buy_value': 'Total Ore Buy Value',
  'component.crafting_calculator.total_ore_bot_value': 'Total Ore Bot Value',
  'component.crafting_calculator.total_mass': 'Total Weight',
  'component.crafting_calculator.close_factory': 'Close factory',
  'component.crafting_calculator.endproducts': 'Endproducts',
  'component.crafting_calculator.amount': 'Amount',
  'component.crafting_calculator.ingredients_parts': 'Ingredients (Parts)',
  'component.crafting_calculator.ingredients_materials': 'Ingredients (Materials)',
  'component.crafting_calculator.add_end_product': 'Add end product',
  'component.crafting_calculator.item_data': 'Data Sheet',
  'component.crafting_calculator.ingredients': 'Ingredients',
  'component.crafting_calculator.craftable_in': 'Craftable in',
  'component.crafting_calculator.usages': 'Usages',
  'component.crafting_calculator.calculate': 'Calculate',

  'component.files.create_dir.title': 'Create directory',

  'component.files.edit_file.title': 'Edit file',

  'component.files.file_browser.navigate_up': 'Navigate up',
  'component.files.file_browser.switch_to_tiles': 'Switch to display mode tiles',
  'component.files.file_browser.switch_to_list': 'Switch to display mode list',
  'component.files.file_browser.show_gallery_mode': 'Show gallery mode',
  'component.files.file_browser.upload_files': 'Upload files',
  'component.files.file_browser.create_dir': 'Create dir',
  'component.files.file_browser.open_folder': 'Open folder',
  'component.files.file_browser.view_file': 'View file',
  'component.files.file_browser.open_file': 'Open file in new tab',
  'component.files.file_browser.edit_file': 'Edit file',
  'component.files.file_browser.delete_file': 'Delete file',
  'component.files.file_browser.show_file_actions_menu': 'Show file actions menu',
  'component.files.file_browser.hide_file_actions_menu': 'Hide file actions menu',
  'component.files.file_browser.apply': 'Apply',

  'component.files.upload_files.title': 'Upload files',
  'component.files.upload_files.instruction': 'Click here to select the files you want to upload. Click on upload on the right to start the upload.',
  'component.files.upload_files.start_upload': 'Start upload',
  'component.files.upload_files.optimization_explanation': 'Image files (f. e. png, jpg, svg, gif) will automatically be optimized to reduce their filesize. This can take up to 2 minutes. Please wait until the upload bar turns green or red.',
  'component.files.upload_files.optimizing': 'optimizing',
  'component.files.upload_files.file_too_big': 'The file $1 exeeds the 5gb upload limit.',
  'component.files.upload_files.pending': 'pending.',
  'component.files.upload_files.uploading': 'uploading.',

  'component.ingame.common.item_browser.title': 'Select an item',
  'component.ingame.common.item_browser.select_item_first': 'Please search for items first.',

  'component.ingame.common.group_browser.title': 'Select a group',
  'component.ingame.common.group_browser.select_group_first': 'Please search for groups first.',

  'component.ingame.common.section_browser.title': 'Select a section',
  'component.ingame.common.section_browser.select_section_first': 'Please search for sections first.',

  'component.ingame.common.stellar_object_browser.title': 'Select a stellar object',
  'component.ingame.common.stellar_object_browser.select_stellar_object_first': 'Please search for stellar objects first.',

  'component.ingame.common.ore_browser.title': 'Select ore',
  'component.ingame.common.ore_browser.select_ore_first': 'Please search for ores first.',

  'component.ingame.edit_item.edit_recipes.add_ingredient': 'Add ingredient',
  'component.ingame.edit_item.edit_recipes.save_first': 'Please save the item first. It has to have an id.',

  'component.ingame.edit_item.original_item.title': 'Original item',
  'component.ingame.edit_item.original_item.save_first': 'Please save the item first.',

  'component.ingame.edit_scan_result.scan_result_resources.title': 'Scan result resources',
  'component.ingame.edit_scan_result.scan_result_resources.add_scan_result_resources': 'Add scan result resources',
  'component.ingame.edit_scan_result.scan_result_resources.save_item_first': 'Please save the scan result first. It has to have an id.',

  'component.ingame.edit_scan_result.save_first': 'Please save the scan result first.',

  'component.ingame.edit_stellar_object.edit_ore_appearances.title': 'Ore appearances',
  'component.ingame.edit_stellar_object.edit_ore_appearances.add_ore_appearances': 'Add ore appearance',
  'component.ingame.edit_stellar_object.edit_ore_appearances.save_first': 'Please save the stellar object first. It has to have an id.',

  'component.ingame.edit_stellar_object.origninal_stellar_object.title': 'Original Stellar Object',
  'component.ingame.edit_stellar_object.origninal_stellar_object.save_first': 'Please save the stellar object first.',

  'component.ingame.edit_group.title': 'Edit group',

  'component.ingame.edit_item.title': 'Edit item',

  'component.ingame.edit_organization.title': 'Edit organization',

  'component.ingame.edit_player.title': 'Edit player',

  'component.ingame.edit_scan_result.title': 'Edit scan result',

  'component.ingame.edit_section.title': 'Edit section',

  'component.ingame.edit_stellar_object.title': 'Edit stellar object',

  'component.ingame.edit_storage.title': 'Edit storage',

  'component.ingame.edit_storage_content.title': 'Edit storage content',

  'component.ingame.project.title.create': 'Create a new ingame project',
  'component.ingame.project.title.edit': 'Edit the ingame project',
  'component.ingame.project.title.view': 'Ingame project',
  'component.ingame.project.project_properties.title': 'Project properties',
  'component.ingame.project.project_images.title': 'Project images',

  'component.ingame.edit_project_type.title': 'Edit project type',

  'component.ingame.select_project_images.title': 'Select project images',

  'component.player_progression.edit_skill.edit_required_skill_level.title': 'Select skill level',
  'component.player_progression.edit_skill.edit_required_skill_level.search_first': 'Please search for skills first.',

  'component.player_progression.edit_skill.edit_skill_levels.add_skill_level': 'Add skill level',
  'component.player_progression.edit_skill.edit_skill_levels.save_first': 'Please save the skill first. It has to have an id.',

  'component.player_progression.common.skill_group_browser.title': 'Select skill group',
  'component.player_progression.common.skill_group_browser.select_skill_group_first': 'Please search for skill groups first.',

  'component.player_progression.edit_skill.original_skill.title': 'Original skill',
  'component.player_progression.edit_skill.original_skill.save_first': 'Please save the skill first',

  'component.player_progression.skill_details.skill_details.required_talent_points': 'Required talent points',
  'component.player_progression.skill_details.skill_details.learning_time_queued': 'Learning time queued',
  'component.player_progression.skill_details.skill_details.learning_time_unqueued': 'Learning time unqueued',
  'component.player_progression.skill_details.skill_details.unlocks_skill_levels': 'Unlocks skill levels',
  'component.player_progression.skill_details.skill_details.unlocked_by': 'Unlocked by',

  'component.player_progression.edit_skill.title': 'Edit skill',

  'component.player_progression.edit_skill_group.title': 'Edit skill group',

  'view.auth.roles.title': 'Access - Roles',

  'view.auth.users.title': 'Access - Users',
  'view.auth.users.resend_confirmation_email': 'Resend confirmation email',
  'view.auth.users.enable_user': 'Enable user',
  'view.auth.users.disable_user': 'Disable user',

  'view.community.member.title': 'Community - Member',
  'view.community.member.copy_section_leaders': 'Copy section leaders',
  'view.community.member.copy_leaders': 'Copy leaders',
  'view.community.member.copy_members': 'Copy members',
  'view.community.member.copy_section_leaders_to_clipboard': 'Copy seciton leaders to clipboard',
  'view.community.member.copy_leaders_to_clipboard': 'Copy leaders to clipboard',
  'view.community.member.copy_members_to_clipboard': 'Copy members to clipboard',
  'view.community.member.section_leaders_copied': 'section leaders copied to clipboard.',
  'view.community.member.leaders_copied': 'leaders copied to clipboard.',
  'view.community.member.members_copied': 'members copied to clipboard.',

  'view.community.organization_chart.title': 'Community - Organization Chart',

  'view.community.quotes.title': 'Community - Quotes',
  'view.community.quotes.delete_quote': 'Delete quote',

  'view.ingame.crafting_calculator.title': 'Crafting Calculator',
  'view.ingame.crafting_calculator.show_factory': 'Show factory',
  'view.ingame.crafting_calculator.everything loaded': 'Items, Groups, Sections, Recipes have been reloaded',

  'view.ingame.constructs.title': 'Ingame - Constructs',
  'view.ingame.constructs.filter_by_organizations': 'Filter by organization',
  'view.ingame.constructs.player_id_owner': 'Player Id (owner)',
  'view.ingame.constructs.player_name_owner': 'Player Name (owner)',
  'view.ingame.constructs.delete_construct': 'Delete construct',
  'view.ingame.constructs.copy_coordinates': 'Copy coordinates',
  'view.ingame.constructs.show_construct_logs': 'Show construct logs',

  'view.ingame.groups.title': 'Ingame - Groups',
  'view.ingame.groups.delete_group': 'Delete group',

  'view.ingame.items.title': 'Ingame - Items',
  'view.ingame.items.delete_item': 'Delete item',

  'view.ingame.ore_appearances.title': 'Ingame - Ore Appearances',

  'view.ingame.organizations.title': 'Ingame - Organizations',
  'view.ingame.organizations.open_community_page': 'Open organization community page',
  'view.ingame.organizations.delete_org': 'Delete organizations',
  'view.ingame.organizations.show_organization_logs': 'Show organization logs',

  'view.ingame.players.title': 'Ingame - Players',
  'view.ingame.players.filter_by_organization': 'Filter by organization',
  'view.ingame.players.open_community_page': 'Open player community page',
  'view.ingame.players.delete_player': 'Delete player',

  'view.ingame.scan_result.title': 'Ingame - Scan Results',
  'view.ingame.players.delete_scan_result': 'Delete scan result',

  'view.ingame.sections.title': 'Ingame - Sections',
  'view.ingame.sections.delete_section': 'Delete section',

  'view.ingame.stellar_objects.title': 'Ingame - Stellar Objects',
  'view.ingame.stellar_objects.delete_stellar_object': 'Delete stellar object',

  'view.ingame.storages.title': 'Ingame - Storages',
  'view.ingame.storages.delete_storage': 'Delete storage',

  'view.ingame.skill_tree.title': 'Player-Progression - Skill Tree',
  'view.ingame.skill_tree.invested_skill_points': 'Invested Skill Points',
  'view.ingame.skill_tree.toggle_edit_mode': 'Toggle edit mode',
  'view.ingame.skill_tree.add_skill_group': 'Add a skill group',
  'view.ingame.skill_tree.add_skill': 'Add a new skill',
  'view.ingame.skill_tree.edit_skill_group': 'Edit this skill group',
  'view.ingame.skill_tree.delete_skill_group': 'Edit this skill group',
  'view.ingame.skill_tree.edit_skill': 'Edit this skill',
  'view.ingame.skill_tree.delete_skill': 'Delete this skill',
  'view.ingame.skill_tree.unlock_skill_level': 'Unlock skill level',
  'view.ingame.skill_tree.lock_skill_level': 'Lock skill level',
  'view.ingame.skill_tree.show_skill_details': 'Show the skill details',
  'view.ingame.skill_tree.no_players_assigned': 'No player assigned to your user',
  'view.ingame.skill_tree.click_for_required': 'Click to get the required skill level',
  'view.ingame.skill_tree.click_to_unlock': 'Click to unlock',
  'view.ingame.skill_tree.click_to_lock': 'Lock to remove unlock',
  'view.ingame.skill_tree.unlocked': 'Skill level unlocked',
  'view.ingame.skill_tree.locked': 'Skill level locked',
}
