<template>
  <div class="tabs">
    <div v-for="item in items"
         class="tabs__item"
         :class="{'tabs__item--selected': value === item.value}"
         @click="emitInput(item.value)"
         v-slashes
    >
      <div class="tabs__item-icon" v-if="item.icon" :style="{backgroundImage: `url('${item.icon}')`}"></div>
      <div class="tabs__item-title">{{item.title}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Tabs",
    props: {
      value: {
        default: null
      },
      items: {
        type: Array,
        default: () => []
      },
    },
    data: () => ({}),
    methods: {
      emitInput(value) {
        if(this.value !== value) {
          this.$emit('input', value);
        }
      }
    }
  }
</script>