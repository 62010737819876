<template>
  <div class="register">
    <my-dialog :show="showMe" @close="close" max-width="400px">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('component.auth.login.request_password_reset')}}</div>
        </div>
        <progress-linear v-if="loading" :indeterminate="true"/>
        <div class="card__content">
          <div class="layout layout--wrap">
            <div class="flex xs12 mt">
              <p>{{__('component.auth.login.request_password_reset_explanation')}}</p>
            </div>
            <div class="flex xs12 mt">
              <text-field
                  v-model="user.username"
                  :label="__('entity.auth.user.username')"
                  :error-messages="errors.username"
                  :hint="__('validation.too_short').replace('$1', 'username').replace('$2', getEnvV('CONSTRAINT_USERNAME_MINLENGTH'))"
                  maxlength="25"
              />
            </div>
            <div class="flex xs6">
              <div class="btn btn--success" :disabled="isInValid" :loading="loading" @click="sendRequest" v-slashes>
                {{ __('common.giveitatry') }}
              </div>
            </div>
            <div class="flex xs6">
              <div class="btn btn--primary" @click="close" v-slashes>{{ __('common.close') }}</div>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import isEmail from 'validator/lib/isEmail';
  import apiDefault from '../../api/default';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { envMixin } from "../../mixins/env";

  export default {
    mixins: [notificationsMixin, localizationMixin, envMixin],
    data: () => ({
      loading: false,
      user: {
        username: '',
      },
      showMe: false,
      changedProperties: [],
      errors: {
        username: [],
      }
    }),
    computed: {
      isInValid() {
        return this.user.username.length < process.env.VUE_APP_CONSTRAINT_USERNAME_MINLENGTH;
      }
    },
    methods: {
      show() {
        this.showMe = true;
      },
      registerChange(property) {
        if (!this.changedProperties.includes(property)) {
          this.changedProperties.push(property);
        }
      },
      isChanged(property) {
        return this.changedProperties.includes(property);
      },
      close() {
        this.showMe = false;
      },
      sendRequest() {
        let isValid = true;
        this.errors = {
        };
        if (this.user.username.length < process.env.VUE_APP_CONSTRAINT_USERNAME_MINLENGTH) {
          this.errors.username.push(this.__('validation.too_short').replace('$1', 'username').replace('$2', process.env.VUE_APP_CONSTRAINT_USERNAME_MINLENGTH));
          isValid = false;
        }

        if (!isValid) {
          return;
        }

        this.loading = true;
        apiDefault.post('/authentication/Unauthenticated/request-password-reset', this.user).then((res) => {
          this.loading = false;
          this.showSuccessNotification(this.__('component.auth.login.check_inbox'));
          this.changedProperties = [];
          this.user.username = '';
          this.close();
          this.$emit('resetPasswordRequested');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      }
    }
  }
</script>