<template>
  <div class="text-area" :class="textAreaClasses">
    <textarea
        :id="inputId"
        :maxlength="maxlength"
        :readonly="readonly"
        v-bind:value="value"
        v-on:input="handleInput"
        @focus="handleFocus"
        @blur="focused = false"
    ></textarea>
    <label :for="inputId">{{label}}</label>
    <div class="text-area__prepend-icon" v-if="prependIcon">
      <icon :icon="prependIcon"/>
    </div>
    <div class="text-area__append-icon" v-if="processedAppendIcon" @click="emitClickAppend">
      <icon :icon="processedAppendIcon"/>
    </div>
    <div class="text-area__info">
      <div class="text-area__hint">{{hint}}</div>
      <div class="text-area__error-messages">{{joinedErrorMessages}}</div>
      <div class="text-area__counter">{{ value ? value.length : 0}}/{{maxlength}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TextArea",
    data: () => ({
      inputId: null,
      focused: false
    }),
    props: {
      value: {
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      maxlength: {
        default: 50000
      },
      hint: {
        type: String,
        default: null
      },
      errorMessages: {
        type: Array,
        default: function () {
          return [];
        }
      },
      prependIcon: {
        type: String,
        default: null
      },
      appendIcon: {
        type: String,
        default: null
      },
      hideDetails: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false
      },
      id: {
        type: String,
        default: null
      },
      fullHeight: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      processedAppendIcon() {
        return this.appendIcon;
      },
      textAreaClasses() {
        return {
          'text-area--focused': this.focused,
          'text-area--error': this.errorMessages.length > 0,
          'text-area--prepend-icon': this.prependIcon !== null,
          'text-area--append-icon': this.appendIcon !== null,
          'text-area--hide-details': this.hideDetails,
          'text-area--full-height': this.fullHeight
        }
      },
      joinedErrorMessages() {
        return this.errorMessages.join('<br/>');
      },
    },
    methods: {
      handleInput(event) {
        this.$emit('input', event.target.value);
        const el = event.target;
        el.style.cssText = 'height:auto';
        el.style.cssText = 'height:' + el.scrollHeight + 'px';
      },
      handleFocus(event) {
        this.focused = true;
        event.target.select();
      },
      emitClickAppend(event) {
        this.$emit('click:append', event);
      },
    },
    created() {
      if (!this.id) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < 32; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        this.inputId = result;
      } else {
        this.inputId = this.id;
      }
    }
  }
</script>