const state = {
  notifications: [],
};

const getters = {
  notifications(state) {
    return state.notifications;
  },
};

const mutations = {
  addNotification(state, payload) {
    state.notifications.push(payload);
  },
  removeNotification(state, payload) {
    state.notifications.splice(
      state.notifications.findIndex((notification) => {
        return payload.key === notification.key;
      }),
      1
    );
  },
  clearNotifications(state) {
    state.notifications = [];
  }
};

const actions = {
  addNotification(context, payload) {
    let color, icon, delay;
    let message = payload.message;
    let type = payload.type;
    switch (type) {
      case 'love':
        color = 'love';
        icon = 'heart';
        delay = 5000;
        break;

      case 'success':
        color = 'success';
        icon = 'check';
        delay = 5000;
        break;

      case 'error':
        color = 'error';
        icon = 'error';
        delay = 15000;
        break;

      case 'info':
        color = 'info';
        icon = 'info';
        delay = 3000;
        break;

      default:
        color = 'warning';
        icon = 'exclamation';
        delay = 10000;
        break;
    }

    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let key = "";

    for (let i = 0; i < 5; i++) {
      key += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    key += new Date().getTime();

    const newNotification = {
      key,
      message,
      color,
      icon
    };
    context.commit('addNotification', newNotification);

    setTimeout(() => {
      context.commit('removeNotification', newNotification);
    }, delay)
  },
  removeNotification(context, payload) {
    context.commit('removeNotification', payload);
  },
  clearNotifications(context) {
    context.commit('clearNotifications');
  },
};

export default {
  state,
  getters,
  mutations,
  actions
}